import React, { useEffect } from "react";
import Modal from "react-bootstrap/Modal";
import Button from "../../../reusableComponents/Buttons/Button";
import { FaRegTimesCircle } from "react-icons/fa";
import { AiOutlineSave } from "react-icons/ai";
import { useForm, Controller } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as Yup from "yup";
import { Text } from "../../../reusableComponents/Inputs";
import { t } from "i18next";
import { Error, Success } from "../../../reusableComponents/Alerts";
import {
  useCreateCurrencyMutation,
  useUpdateCurrencyMutation,
  useGetCurrencyQuery,
} from "../../../../redux/api/contractsApi";
// Validation schema using Yup
const validationSchema = Yup.object().shape({
  currency: Yup.string().required(t("validation.required")),
});

const CurrenciesModal = ({ modalOptions, setModalOptions, refetch }) => {
  const { data } = useGetCurrencyQuery(modalOptions.id, { skip: !modalOptions.id });
  console.log(data);
  const [createItem] = useCreateCurrencyMutation();
  const [updateItem] = useUpdateCurrencyMutation();
  // Initialize useForm with default values and validation schema
  const {
    control,
    handleSubmit,
    setValue,
    reset,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(validationSchema),
    defaultValues: {
      currency: "",
    },
  });
  const toggle = () => {
    setModalOptions({ isOpen: !modalOptions.isOpen, id: null });
  };

  // Effect to set form values when editing
  useEffect(() => {
    if (modalOptions.isOpen) {
      if (modalOptions.type === "edit" && data) {
        // Populate the form with existing data
        setValue("currency", data?.currency?.currency || "");
      } else {
        // Reset form for new entries
        reset({ currency: "" });
      }
    }
  }, [modalOptions, data, setValue, reset]);

  const onSubmit = async (formData) => {
    if (modalOptions.type === "edit") {
      console.log("Updating data:", formData);
      const response = await updateItem({ id: modalOptions.id, currency: formData });
      if (!response.error) {
        refetch();
        Success(
          `${t("caymland.core.update.step.success")}`,
          t("caymland.message.type.successfully", { type: t("caymland.core.type.edited") })
        );
      } else {
        Error(t("caymland.message.error"));
      }
    } else {
      console.log("Creating new data:", formData);
      const response = await createItem({ ...formData });
      if (!response.error) {
        refetch();
        Success(
          `${t("caymland.core.update.step.success")}`,
          t("caymland.message.type.successfully", { type: t("caymland.lead.segments.contacts.added") })
        );
      } else {
        Error(t("caymland.message.error"));
      }
    }
    // Close the modal after submission
    reset();
    toggle();
  };

  const buttons = [
    {
      name: "close",
      title: `${t("caymland.core.close")}`,
      icon: <FaRegTimesCircle size={15} />,
      onClick: () => toggle(),
    },
    {
      name: "save",
      title: modalOptions?.type === "edit" ? t("caymland.core.form.update") : t("caymland.core.form.add"),
      icon: <AiOutlineSave size={15} />,
      onClick: handleSubmit(onSubmit),
    },
  ];

  return (
    <Modal show={modalOptions.isOpen} onHide={toggle}>
      <Modal.Header closeButton>
        <Modal.Title>{modalOptions?.type === "edit" ? `Edit ${data?.currency?.currency}` : "Add Currency"}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <form>
          <Controller
            name="currency"
            control={control}
            render={({ field }) => (
              <Text
                label={t("Currency")}
                {...field} // Connects the field to react-hook-form's state
                errors={errors.currency?.message}
              />
            )}
          />
        </form>
      </Modal.Body>
      <Modal.Footer>
        <Button buttons={buttons} />
      </Modal.Footer>
    </Modal>
  );
};

export default CurrenciesModal;
