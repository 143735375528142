import React, { useState, useEffect, useRef } from "react";
import Select from "react-select";
import { countryData, localeData, groupedOptions } from "../../../../../reusableComponents/data";
import { DatePicker as DataSelect, Text } from "../../../../../reusableComponents/Inputs";
import { t } from "i18next";

const debounce = (func, delay) => {
  let timerId;
  return (...args) => {
    if (timerId) clearTimeout(timerId);
    timerId = setTimeout(() => {
      func(...args);
    }, delay);
  };
};

const ValueEditor = (props) => {
  const { fieldData, value, field, handleOnChange, fields } = props;

  console.log(field);

  console.log(value);
  const [localValue, setLocalValue] = useState(value);
  const [data, setData] = useState();
  const debouncedHandleOnChange = useRef(debounce(handleOnChange, 2000)).current;

  console.log(props);
  useEffect(() => {
    if (data) {
      debouncedHandleOnChange(data);
    }
  }, [data, debouncedHandleOnChange]);

  function isPlainObject(obj) {
    return Object.prototype.toString.call(obj) === "[object Object]";
  }

  function getOptions(field) {
    if (isPlainObject(field.list)) {
      return Object.entries(field.list).map(([key, value]) => ({
        value: key,
        label: value,
      }));
    }
    if (Array.isArray(field.list) && field.list.length > 0) {
      return field.list.map((item, index) => ({
        value: typeof item === "object" ? item.value : index,
        label: typeof item === "object" ? item.label : item,
      }));
    }

    // Handle predefined types
    switch (field.type) {
      case "locale":
        return localeData || [];
      case "country":
        return countryData || [];
      case "region":
        return groupedOptions || [];
      // Add other predefined types here if needed
      default:
        return [];
    }
  }

  const isMulti =
    props.operator === "including" ||
    props.operator === "excluding" ||
    props.operator === "in" ||
    props.operator === "!in";

  const isDisabled =
    props.operator === "!empty" || props.operator === "empty" || props.isDisabled || props.operator === "not_empty";

  const updatedValue = isDisabled ? " " : getOptions(field)?.find((option) => option.value == value);

  // Show Select if field.list is non-empty or based on predefined types
  const showSelect =
    (Array.isArray(field.list) && field.list.length > 0) ||
    (isPlainObject(field.list) && Object.keys(field.list).length > 0) ||
    ["multiselect", "country", "locale", "region"].includes(field.type) ||
    isMulti;

  console.log(field);

  const defaultOptions = fields?.[0]?.properties?.list
    ? Object.entries(fields[0].properties.list).map(([key, value]) => ({
        value: key,
        label: value,
      }))
    : [];

  if (showSelect) {
    return (
      <div style={{ width: "30%" }}>
        <Select
          isLoading={props.loading}
          isDisabled={isDisabled}
          options={getOptions(field)}
          placeholder={isDisabled ? "Disabled" : "Select..."}
          value={updatedValue ?? null}
          onChange={(e) => {
            if (isDisabled) {
              handleOnChange("");
            } else if (isMulti) {
              const selectedIds = e ? e.map((option) => option.value) : [];
              handleOnChange(selectedIds);
            } else {
              const selectedValue = e ? e.value : "";
              handleOnChange(selectedValue);
            }
          }}
          isMulti={isMulti}
          theme={props.selectTheme}
          styles={props.selectStyles}
        />
      </div>
    );
  }
  console.log(props.operator);

  // Render date or text input for other field types
  switch (field.type) {
    case "date":
    case "datetime":
      const handleDateChange = (input) => {
        let localDate;

        // Attempt to parse the input as a date
        localDate = new Date(input);

        // Check if the parsed date is valid
        if (!isNaN(localDate.getTime())) {
          // It's a valid date
          // Check if the field type is 'datetime' (optional, if you have such a context)
          if (field.type === "datetime") {
            // Extract the date and time parts
            const year = localDate.getFullYear();
            const month = String(localDate.getMonth() + 1).padStart(2, "0"); // Months are zero-indexed
            const day = String(localDate.getDate()).padStart(2, "0");
            const hours = String(localDate.getHours()).padStart(2, "0");
            const minutes = String(localDate.getMinutes()).padStart(2, "0");

            const formattedDateTime = `${year}-${month}-${day} ${hours}:${minutes}`; // Formats as 'YYYY-MM-DD HH:mm'
            setData(formattedDateTime);
          } else {
            const formattedDate = localDate.toISOString().split("T")[0];
            setData(formattedDate);
          }
        } else {
          setData(input);
        }
      };

      return (
        <div style={{ width: "30%", marginTop: "20px" }}>
          <DataSelect
            isLoading={props.loading}
            onChange={handleDateChange}
            value={props.value}
            datestring
            marginBottom={false}
            showTimeSelect={field.type === "datetime"}
            disabled={isDisabled}
            placeholderText={
              isDisabled ? t("caymland.queue.config.protocol.disabled") : `${t("caymland.core.select")}...`
            }
          />
        </div>
      );
    default:
      return (
        <div style={{ width: "30%" }}>
          <Text
            name="website"
            value={localValue}
            onChange={(e) => setLocalValue(e.target.value)}
            onBlur={() => handleOnChange(localValue)}
            placeholder={isDisabled ? t("caymland.queue.config.protocol.disabled") : t("caymland.core.type.type")}
            disabled={isDisabled}
            marginBottom
          />
        </div>
      );
  }
};

export default ValueEditor;
