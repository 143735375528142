import { t } from "i18next";
import Moment from "moment";

const EdgeLabel = (event) => {
  function formatTime(timestamp) {
    if (typeof timestamp === "string" && timestamp.length === 5 && timestamp.includes(":")) {
      return timestamp;
    } else {
      const date = new Date(timestamp);
      const hours = date.getHours().toString().padStart(2, "0");
      const minutes = date.getMinutes().toString().padStart(2, "0");
      return `${hours}:${minutes}`;
    }
  }

  const choices = [
    {
      label: t("caymland.campaign.event.intervalunit.choice.i"),
      value: "i",
    },
    {
      label: t("caymland.campaign.event.intervalunit.choice.h"),
      value: "h",
    },
    {
      label: t("caymland.campaign.event.intervalunit.choice.d"),
      value: "d",
    },
    {
      label: t("caymland.campaign.event.intervalunit.choice.m"),
      value: "m",
    },
    {
      label: t("caymland.campaign.event.intervalunit.choice.y"),
      value: "y",
    },
  ];

  const {
    triggerInterval,
    triggerIntervalUnit,
    triggerHour,
    triggerRestrictedStartHour,
    triggerRestrictedStopHour,
    triggerDate,
  } = event;
  let label = "";

  const getUnitLabel = (unitValue) => choices.find((choice) => choice.value === unitValue)?.label || unitValue;

  if (event.triggerMode === "interval") {
    if (triggerIntervalUnit && triggerInterval) {
      label += t("caymland.campaign.connection.wait_for", {
        interval: `${triggerInterval} ${getUnitLabel(triggerIntervalUnit)}`,
      });
      if (triggerHour) label += " " + t("caymland.campaign.connection.at_hour", { Stunde: triggerHour });
      else if (triggerRestrictedStartHour && triggerRestrictedStopHour) {
        label += ` ${t("caymland.core.operator.between")} ${formatTime(triggerRestrictedStartHour)} ${t(
          "caymland.core.and"
        )} ${formatTime(triggerRestrictedStopHour)}`;
      }
    }
  } else if (event.triggerMode === "date" && triggerDate) {
    label = `${t("caymland.campaign.connection.trigger.date.label", {
      full: Moment(triggerDate).format("DD.MM.YY HH:mm"),
    })}`;
  }

  return label.trim();
};

export default EdgeLabel;
