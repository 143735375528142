import React, { useState, useEffect } from "react";
import { Nav, NavItem, NavLink, TabContent, TabPane } from "reactstrap";
import { BiAddToQueue } from "react-icons/bi";
import { BsTrash } from "react-icons/bs";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import ClassicEditor from "ckeditor5-custom-build/build/ckeditor";
import DynamicContentFilters from "./DynamicContentFilters";
import { LogIn } from "react-feather";
import { TextArea } from "../../../../reusableComponents/Inputs";
import { Delete } from "@mui/icons-material";
import { t } from "i18next";

const DynamicContentTab = ({
  instance,
  selectSubTab,
  activeSubTab,
  activeMainTab,
  addNewSubTab,
  deleteSubTab,
  updateInputValue,
  setMainTabs,
  loading,
  fields,
}) => {
  const updateSubTabSelectValues = (tabIndex, subTabId, query) => {
    setMainTabs((prevMainTabs) => {
      const newMainTabs = [...prevMainTabs];
      const subTabIndex = newMainTabs[tabIndex].subtabs.findIndex((subtab) => subtab.id === subTabId);
      if (subTabIndex !== -1) {
        newMainTabs[tabIndex].subtabs[subTabIndex].selectValues = query;
      }
      return newMainTabs;
    });
  };

  return (
    <div>
      <div className="col-12">
        <Nav className="nav-primary w-full flex custom-nav-trash" tabs>
          <div
            className="d-flex"
            style={{
              flexWrap: "wrap",
            }}
          >
            {instance.subtabs.map(
              (tab, index) =>
                (tab.name !== t("caymland.mailbox.list.action.new") || activeMainTab !== 0) && (
                  <NavItem key={index} style={{ borderBottom: "none" }}>
                    <NavLink
                      href="#"
                      className={activeSubTab === index ? "active" : ""}
                      style={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        color: "#313131",
                        textTransform: "capitalize",
                      }}
                      onClick={() => {
                        if (tab.name === t("caymland.mailbox.list.action.new")) {
                          addNewSubTab();
                        }
                        selectSubTab(index, tab.name);
                      }}
                    >
                      {tab.name === t("caymland.mailbox.list.action.new") && (
                        <>
                          <BiAddToQueue size={17} /> &nbsp;
                        </>
                      )}
                      {tab.name}
                    </NavLink>
                  </NavItem>
                )
            )}
          </div>
          <div>
            {activeMainTab === 0 ? null : (
              <button onClick={deleteSubTab} className="delete-component" style={{ width: "50px", height: "35px" }}>
                <Delete />
              </button>
            )}
          </div>
        </Nav>
        <TabContent activeTab={activeSubTab}>
          {instance.subtabs.map((tab, index, i) => (
            <TabPane key={index} tabId={index}>
              <div className="w-100 mb-3 py-20">
                <TextArea
                  showSmallEditor={false}
                  inserttoken={true}
                  label={
                    tab.name === "Default"
                      ? t("caymland.core.form.default")
                      : t("caymland.core.dynamicContent.default_content")
                  }
                  required={true}
                  value={tab?.inputValue ?? ""}
                  onChange={(data) => {
                    updateInputValue(activeMainTab, activeSubTab, data);
                  }}
                />
              </div>
              {tab.name !== "Default" && instance.subtabs[activeSubTab] && (
                <DynamicContentFilters
                  query={instance.subtabs[activeSubTab]?.selectValues || { combinator: "and", rules: [] }}
                  setQuery={(newQuery) =>
                    updateSubTabSelectValues(activeMainTab, instance.subtabs[activeSubTab].id, newQuery)
                  }
                  loading={loading}
                  fields={fields}
                />
              )}
            </TabPane>
          ))}
        </TabContent>
      </div>
    </div>
  );
};

export default DynamicContentTab;
