import React, { useEffect, useState } from "react";
import { Controller } from "react-hook-form";
import { useGetContractOptionsQuery } from "../../../../redux/api/contractsApi";
import { useGetUserSelectOptionsQuery } from "../../../../redux/api/usersApi";
import { Checkbox, DatePicker, Select, Text } from "../../../reusableComponents/Inputs";
import { contactsApi } from "../../../../redux/api/contactsApi";

const Sidebar = ({ control, errors, watch }) => {
  const { data: userOptions } = useGetUserSelectOptionsQuery({});
  const { data: typeOptions } = useGetContractOptionsQuery({ type: "types" });
  const { data: currencyOptions } = useGetContractOptionsQuery({ type: "currencies" });
  const { data: checklistOptions } = useGetContractOptionsQuery({ type: "checklists" });
  const [contactsOptions, setContactsOptions] = useState([]);

  const [triggerContactsQuery] = contactsApi.useLazyGetContactsQuery();
  const [debounceTimeout, setDebounceTimeout] = useState(null);

  const statusOptions = [
    { label: "Open", value: 1 },
    { label: "Offered", value: 2 },
    { label: "Finished", value: 3 },
    { label: "Canceled", value: 4 },
    { label: "Confirmed", value: 5 },
  ];

  const retrieveContacts = (inputValue, bundle, includeGlobal = true) =>
    new Promise(async (resolve, reject) => {
      if (debounceTimeout) {
        clearTimeout(debounceTimeout);
      }

      const newTimeout = setTimeout(async () => {
        const query = {
          pageIndex: 0,
          pageSize: sessionStorage.getItem("pageSize") ? parseInt(sessionStorage.getItem("pageSize"), 10) : 30,
          orderByDir: "desc",
          orderBy: "last_active",
        };
        if (inputValue.length) {
          query.search = inputValue;
        }

        const response = await triggerContactsQuery(query);
        if (!response.error) {
          const contactsData = response?.data?.data;
          const contactsArray = contactsData?.map((contact) => {
            const firstname = contact?.fields?.all?.firstname || "";
            const lastname = contact?.fields?.all?.lastname || "";
            const email = contact?.fields?.all?.email || "";

            const label = `${firstname} ${lastname}`.trim();

            return {
              label: label ? `${label}` : email,
              value: contact?.id,
            };
          });

          resolve(contactsArray);
          setContactsOptions(contactsArray);
        } else {
          reject();
        }
      }, 500);

      setDebounceTimeout(newTimeout);
    });

  return (
    <div className="tw-w-[100%] tw-flex tw-flex-col tw-gap-4">
      {/* Text Input */}
      <Controller
        control={control}
        name={"name"}
        render={({ field: { name, onChange, value } }) => (
          <Text
            name={name}
            value={value}
            onChange={onChange}
            marginBottom
            label={"Name"}
            required={true}
            errors={errors && errors[name]}
          />
        )}
      />

      {/* Select Inputs */}
      <Controller
        control={control}
        name={"lead"}
        render={({ field: { name, onChange, value } }) => (
          <Select
            name={name}
            value={contactsOptions?.find((item) => item.value == value) || value}
            onChange={onChange}
            margin
            options={retrieveContacts}
            label={"Contract Partner"}
            required={true}
            errors={errors && errors[name]}
          />
        )}
      />
      <Controller
        control={control}
        name={"contacts"}
        render={({ field: { name, onChange, value } }) => (
          <Select
            name={name}
            value={contactsOptions?.filter((item) => value?.includes(item.value)) || []}
            onChange={(selectedOptions) => {
              // Map selected options to an array of their `value` (IDs)
              const selectedIds = selectedOptions.map((option) => option.value);
              onChange(selectedIds); // Pass the array of IDs to the Controller
            }}
            margin
            options={retrieveContacts}
            isMulti
            label={"Additional Leads"}
            errors={errors && errors[name]}
          />
        )}
      />

      <Controller
        control={control}
        name={"user"}
        render={({ field: { name, onChange, value } }) => (
          // console.log(value.value)
          console.log(value),
          (
            <Select
              name={name}
              label={"User"}
              value={value}
              onChange={onChange}
              margin
              options={userOptions}
              errors={errors && errors[name]}
            />
          )
        )}
      />
      <Controller
        control={control}
        name={"type"}
        render={({ field: { name, onChange, value } }) => (
          <Select
            name={name}
            value={typeOptions?.find((item) => item.value == value) || value}
            onChange={onChange}
            margin
            options={typeOptions}
            label={"Contract Type"}
            errors={errors && errors[name]}
          />
        )}
      />

      <Controller
        control={control}
        name={"status"}
        render={({ field: { name, onChange, value } }) => (
          <Select
            name={name}
            value={value}
            onChange={onChange}
            margin
            options={statusOptions}
            label={"Contract Status"}
            required={true}
            errors={errors && errors[name]}
          />
        )}
      />
      <Controller
        control={control}
        name={"currency"}
        render={({ field: { name, onChange, value } }) => (
          <Select
            name={name}
            value={currencyOptions?.find((item) => item.value == value) || value}
            onChange={onChange}
            margin
            options={currencyOptions}
            label={"Currency"}
            required={true}
            errors={errors && errors[name]}
          />
        )}
      />

      {/* Date Inputs */}
      <div className="tw-flex tw-items-center tw-gap-4">
        <Controller
          control={control}
          name={"dateValidFrom"}
          render={({ field: { name, onChange, value } }) => (
            <DatePicker
              name={name}
              value={value}
              onChange={onChange}
              marginBottom={false}
              label={"Valid From"}
              showTimeSelect
              errors={errors && errors[name]}
            />
          )}
        />
        <Controller
          control={control}
          name={"dateValidTo"}
          render={({ field: { name, onChange, value } }) => (
            <DatePicker
              name={name}
              value={value}
              onChange={onChange}
              marginBottom={false}
              label={"Valid To"}
              showTimeSelect
              errors={errors && errors[name]}
            />
          )}
        />
      </div>

      {/* Checkbox for Continue Automatically */}
      <Controller
        control={control}
        name={"continueAutomatically"}
        render={({ field: { name, onChange, value } }) => (
          <Checkbox
            name={name}
            value={value}
            onChange={onChange}
            marginBottom="0px"
            label={"Continue Automatically"}
            errors={errors && errors[name]}
          />
        )}
      />

      {/* Dynamic Checklist Checkboxes */}
      {checklistOptions?.length > 0 && (
        <Controller
          control={control}
          name={"checklists"}
          render={({ field: { name, onChange, value } }) => (
            <div>
              <p className="tw-font-[13px] tw-mb-2 tw-text-[#6C757D]">Checklists</p>
              {checklistOptions?.map((option) => (
                <div key={option.value} className="tw-flex tw-items-center tw-gap-2 tw-mb-[10px]">
                  <input
                    type="checkbox"
                    id={`checklist-${option.value}`}
                    name={`${name}[${option.value}]`}
                    value={option.value}
                    checked={value?.includes(option.label)}
                    onChange={(e) => {
                      const checked = e.target.checked;
                      const updatedValues = checked
                        ? [...(value || []), option.label]
                        : (value || []).filter((v) => v !== option.label);
                      onChange(updatedValues);
                    }}
                    style={{
                      width: "16px",
                      height: "16px",
                      cursor: "pointer",
                    }}
                  />
                  <label
                    htmlFor={`checklist-${option.value}`}
                    style={{ margin: 0, fontSize: "16px", fontWeight: "500", color: "#495057" }}
                  >
                    {option.label}
                  </label>
                </div>
              ))}
              {errors && errors[name] && <div className="text-danger">{errors[name].message}</div>}
            </div>
          )}
        />
      )}
    </div>
  );
};

export default Sidebar;
