import React, { useEffect, useRef, useState } from "react";
import Select from "react-select";
import { Controller } from "react-hook-form";
import { t } from "i18next";
import AddCompanyModal from "../../reusableComponents/CompanyModal/AddCompanyModal";
import { useGetCompaniesOptionsQuery } from "../../../redux/api/companiesApi";
import { Label } from "../../reusableComponents/Inputs";

function StaticCompanySelect({ control, viewEdit = false, getValues, errors = false, setValue }) {
  const [options, setOptions] = useState([]);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [query, setQuery] = useState("");
  const [getNewCompany, setGetNewCompany] = useState({});

  const selectRef = useRef(null);

  // Fetch companies based on search query
  const { data } = useGetCompaniesOptionsQuery(`search=${query}`);

  useEffect(() => {
    const newOptions = data
      ? [{ label: "Neu erstellen...", value: "create_new" }, ...data.map((d) => ({ ...d, value: d.value.toString() }))]
      : [];

    // Merge new options with currently selected options not included in the fetch result
    setOptions((prevOptions) => {
      const selectedValues = getValues("companies") || [];
      const existingSelectedOptions = prevOptions.filter((option) => selectedValues.includes(option.value));
      // Deduplicate while preserving order
      const allOptions = [...existingSelectedOptions, ...newOptions];
      const uniqueOptions = Array.from(new Map(allOptions.map((option) => [option.value, option])).values());
      return uniqueOptions;
    });
  }, [data, getValues]);

  useEffect(() => {
    if (getNewCompany?.value) {
      const newOption = { label: getNewCompany.label, value: getNewCompany.value.toString() };
      setOptions((prevOptions) => {
        // Avoid adding duplicates
        if (prevOptions.some((option) => option.value === newOption.value)) {
          return prevOptions;
        }
        return [...prevOptions, newOption];
      });

      // Update the form value to include this new company
      const newValue = [...(getValues("companies") || []), getNewCompany];
      setValue("companies", newValue, { shouldValidate: true });

      setIsModalOpen(false); // Close the modal if it's open
    }
  }, [getNewCompany, setValue, getValues]);

  return (
    <div className="">
      <Label name="companies" label={t("caymland.core.company")} />
      <AddCompanyModal isModalOpen={isModalOpen} setIsModalOpen={setIsModalOpen} setGetNewCompany={setGetNewCompany} />
      <Controller
        control={control}
        name="companies"
        render={({ field: { onChange, value: selectedCompanyIds } }) => {
          // Ensure that the value array for the Select component is deduplicated
          // Deduplicate valueOptions by value to ensure each selected option is unique

          return (
            <Select
              cacheOptions
              label={viewEdit ? "" : t("caymland.core.company")}
              isClearable
              placeholder={t("caymland.core.select")}
              viewEdit={viewEdit}
              className="w-full"
              options={options}
              value={selectedCompanyIds}
              isMulti
              onChange={(selectedOptions) => {
                // Filter out the "create_new" option if it's selected
                const filteredOptions = selectedOptions.filter((option) => option.value !== "create_new");

                // Directly pass the filtered objects to the onChange method from react-hook-form
                onChange(filteredOptions); // Update form state with the selected options as objects

                // Check if "Create New..." option is among the selected options
                if (selectedOptions.some((option) => option.value === "create_new")) {
                  setIsModalOpen(true); // Open the modal
                  // Optionally reset the select input to remove "Create New..." from visible selections
                  // This may require a way to programmatically clear the selection, which could depend on additional state management or ref usage.
                }

                setQuery(""); // Reset query after selection
              }}
              onInputChange={(inputValue, { action }) => {
                if (action === "input-change") {
                  setQuery(inputValue);
                }
              }}
              onKeyDown={(e) => {
                if (e.key === "ArrowUp") {
                  console.log("Navigating up");
                }
                if (e.key === "ArrowDown") {
                  console.log("Navigating down");
                }
                if (e.key === "Enter") {
                  console.log("Option selected");
                }
                if (selectRef.current) {
                  selectRef.current.focus(); // Ensure dropdown stays focused
                }
              }}
              isSearchable
              styles={{
                control: (base) => ({
                  ...base,
                  outline: "none !important",
                  minHeight: viewEdit ? "34px" : "40px",
                  fontSize: "15px",
                  backgroundColor: viewEdit ? "inherit" : "white",
                  border: viewEdit ? "0" : "",
                  marginBottom: viewEdit ? "3px" : "0",
                  boxShadow: viewEdit ? "none" : "",
                  borderColor: errors ? "#d32f2f" : base.borderColor,
                  transition: "transform 0.3s ease, box-shadow 0.3s ease",
                  "&:hover": {
                    transform: viewEdit ? "none" : "scale(1.003)",
                    boxShadow: viewEdit ? "none" : "0 6px 20px rgba(0, 0, 0, 0.1)",
                    borderColor: errors ? "#d32f2f" : base["&:hover"].borderColor,
                  },
                }),
                option: (provided, state) => ({
                  ...provided,
                  backgroundColor: state.isFocused ? "#347AA6" : "white",
                  color: state.isFocused ? "white" : "#333",
                  padding: "10px 2px",
                  fontSize: "15px",
                  transition: "background-color 0.2s ease",
                  paddingLeft: "20px",
                  "&:active": {
                    color: "white",
                    backgroundColor: "#135f95",
                  },
                  "&:hover": {
                    color: "white",
                    backgroundColor: "#135f95",
                  },
                  "&:focus": {
                    color: "white",
                  },
                }),
                multiValueRemove: (styles) => ({
                  ...styles,
                  color: "#606060",
                  ":hover": {
                    backgroundColor: "#135f95",
                    color: "white",
                  },
                }),
                multiValue: (styles) => ({
                  ...styles,
                  fontSize: "15px",
                  backgroundColor: " #e7e7e7",
                  borderRadius: "5px",
                }),
                multiValueLabel: (styles) => ({
                  ...styles,
                  fontSize: "15px",
                  color: "#606060",
                }),
                placeholder: (styles) => ({
                  ...styles,
                  color: "#6C757D",
                  fontSize: viewEdit ? "13px" : "15px",
                }),
                group: (base, state) => ({
                  ...base,
                  // backgroundColor: "#f6f6f6",
                  padding: "10px 2px",
                }),

                groupHeading: (base, state) => ({
                  ...base,
                  fontWeight: "bold",
                  paddingLeft: "5px",
                  color: "#333",
                }),
                menu: (provided) => ({ ...provided, zIndex: 99999999 }),
              }}
            />
          );
        }}
      />
    </div>
  );
}

export default StaticCompanySelect;
