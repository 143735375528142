import React, { useState, useEffect, Fragment, useRef, useMemo } from "react";
import { Nav, NavItem, NavLink, TabContent, TabPane } from "reactstrap";
import ViewFieldsForm from "./ViewFieldsForms";
import { useForm } from "react-hook-form";
import { useUpdateContactsMutation } from "../../../../redux/api/contactsApi";
import { AiOutlineSave } from "react-icons/ai";
import { TbEdit } from "react-icons/tb";
import KeyboardDoubleArrowDownIcon from "@mui/icons-material/KeyboardDoubleArrowDown";
import KeyboardDoubleArrowUpIcon from "@mui/icons-material/KeyboardDoubleArrowUp";
import moment from "moment";
import { t } from "i18next";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { FaRegTimesCircle } from "react-icons/fa";
import { useSelector } from "react-redux";
import { BsFillTelephoneOutboundFill, BsLink45Deg, BsSendFill } from "react-icons/bs";
import { FaExternalLinkAlt } from "react-icons/fa";
import { MdOutlineExpandMore } from "react-icons/md";
import { MdExpandLess } from "react-icons/md";

const FieldsForm = ({ contactFields, data, setShow, setMessage, setPointsValue, setBackgroundToast }) => {
  const [currentTab, setCurrentTab] = useState("core");
  const [primaryColorTab, setPrimaryColorTab] = useState(0);
  const [groupedFields, setGroupedFields] = useState({});
  const [editableField, setEditableField] = useState(null);
  const [isEditAll, setIsEditAll] = useState(false); // New state for edit all functionality
  const [resetData, setResetData] = useState();
  const [isFieldChanged, setIsFieldChanged] = useState(false); // Track changes
  const [fieldDisplayLimits, setFieldDisplayLimits] = useState({});
  const [initialValues, setInitialValues] = useState({});
  const countries = useSelector((state) => state.settings.data.countries);
  const locales = useSelector((state) => state.settings.data.locales);
  const regions = useSelector((state) => state.settings.data.regions);

  const {
    register,
    control,
    handleSubmit,
    watch,
    reset,
    setValue,
    formState: { errors, dirtyFields, touchedFields },
  } = useForm({
    resolver: yupResolver(),
  });
  useEffect(() => {
    if (data) {
      const mergedData = {
        ...data.fields.all,
        stage: data?.stage?.id || null,
        tags: data?.tags?.map((tag) => tag?.id) || [],
        owner: data?.owner?.id,
      };
      reset(mergedData);
      setInitialValues(mergedData);
    }
  }, [data, reset]);
  // Email validation function using regex
  const isValidEmail = (email) => {
    const regex = /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)+\d*$/;
    return regex.test(String(email).toLowerCase());
  };

  // Example usage within your component
  const email = watch("email"); // Assuming you're watching the email field

  // Check if the email is valid
  const emailIsValid = useMemo(() => isValidEmail(email), [email]);

  const toggleEditable = (fieldId) => {
    setEditableField(fieldId);
    setIsFieldChanged(false);
  };

  const toggleEditAll = () => {
    setIsEditAll(!isEditAll);
    setEditableField(null);
  };

  const handleFieldChange = () => {
    setIsFieldChanged(true);
  };

  const formatFieldValue = (field, value) => {
    if (value === undefined || value === null) {
      return "";
    }

    switch (field?.type) {
      case "boolean":
        return value === 1 || value === true
          ? field?.properties?.yes
          : value === 0 || value === false
          ? field?.properties?.no
          : "";
      case "select":
        const option = field?.properties?.list?.find((item) => item?.value === value);
        return option ? option?.label : value;
      case "multiselect":
        // Normalize `value` to always be an array
        const normalizedValue = Array.isArray(value)
          ? value
          : typeof value === "string"
          ? value.split("|") // Split the pipe-separated string into an array
          : []; // Default to an empty array if value is neither an array nor a string

        return normalizedValue.length > 0
          ? normalizedValue
              .map((val) => {
                // Find the corresponding option in the list by matching the value
                const option = field?.properties?.list?.find((item) => item?.value === val);
                // Return the label if found, otherwise return the value itself as fallback
                return option ? option.label : val;
              })
              .join(", ")
          : "None"; // Placeholder text when value is empty

      case "country":
        return countries.find((country) => country?.value === value)?.label;
      case "locale":
        return locales.find((locale) => locale?.value === value)?.label;
      case "datetime":
        return moment(value).format("DD.MM.YYYY HH:mm") || "";
      case "date":
        return moment(value).format("DD.MM.YYYY ") || "";
      default:
        return value?.length > 30 ? value?.slice(0, 30) + "..." : value;
    }
  };

  useEffect(() => {
    if (contactFields) {
      function groupFieldsByTab(fields) {
        const grouped = {};
        fields.forEach((field) => {
          if (field.isPublished && field.isVisible) {
            const groupName = field.group || "other";
            if (!grouped[groupName]) {
              grouped[groupName] = [];
            }
            grouped[groupName].push(field);
          }
        });

        if (grouped["core"]) {
          const orderedGrouped = { core: grouped["core"] };
          Object.keys(grouped).forEach((groupName) => {
            if (groupName !== "core") {
              orderedGrouped[groupName] = grouped[groupName];
            }
          });
          return orderedGrouped;
        }

        return grouped;
      }

      const grouped = groupFieldsByTab(contactFields);
      setGroupedFields(grouped);

      // Initialize display limits for each tab
      const displayLimits = Object.keys(grouped).reduce((acc, groupName) => {
        acc[groupName] = 16;
        return acc;
      }, {});
      setFieldDisplayLimits(displayLimits);
    }
  }, [contactFields]);
  const formRef = useRef(); // Create a ref for the form

  const changeDynamicTab = (index, groupName) => {
    setPrimaryColorTab(index);
    setCurrentTab(groupName);
  };

  const [updateContact] = useUpdateContactsMutation(); // Initialize the mutation hook
  console.log(dirtyFields);
  console.log(contactFields);
  const updateFields = async () => {
    const payload = Object.keys(dirtyFields).reduce((acc, field) => {
      const value = watch(field);

      // Find the field definition in contactFields using the alias
      const fieldDefinition = contactFields.find((contactField) => contactField.alias === field);

      // Check if the field type is multiselect and handle accordingly
      if (fieldDefinition && fieldDefinition.type === "multiselect") {
        acc[field] = value === "" ? [] : value; // Empty array for empty string, otherwise use the value
      } else {
        acc[field] = value === "" ? "" : value; // Empty string for empty string, otherwise use the value
      }

      return acc;
    }, {});

    try {
      const response = await updateContact({
        id: data.id,
        ...payload,
      }).unwrap();

      if (!response.errors) {
        reset(response?.contact?.fields?.all);
        setPointsValue(response?.contact?.points);
        setResetData(response?.contact?.fields?.all);
        setEditableField(null);
        setIsEditAll(false);
        setShow(true);
        setMessage(t("caymland.core.setmessage.contact"));
        setBackgroundToast("1");
        setTimeout(() => {
          setShow(false);
          setMessage("");
        }, 3000);
      } else {
        setShow(true);
        setMessage(response.error.data.errors[0].message);
        setBackgroundToast("2");
        setTimeout(() => {
          setShow(false);
          setMessage("");
          setBackgroundToast("1");
        }, 3000);
      }
    } catch (error) {
      // Handle error
      console.error("Error updating contact:", error);
    }
  };

  const toggleFieldDisplay = (groupName) => {
    setFieldDisplayLimits({
      ...fieldDisplayLimits,
      [groupName]: fieldDisplayLimits[groupName] > 16 ? 16 : groupedFields[groupName].length,
    });
  };
  const getFieldNameFromId = (fieldId) => {
    // Placeholder for actual logic
    // This could be a lookup in an array or object that maps field IDs to names
    const field = contactFields.find((f) => f.id === fieldId);
    return field ? field.alias : null; // assuming 'alias' is the form field name
  };

  function calculateAge(birthday) {
    if (!birthday) return ""; // If no date is provided, return an empty string

    const birthDate = new Date(birthday);

    // Check if the provided birthday is a valid date
    if (isNaN(birthDate.getTime())) return "";

    const today = new Date();
    let age = today.getFullYear() - birthDate.getFullYear();
    const monthDifference = today.getMonth() - birthDate.getMonth();

    // Adjust age if birthday hasn't occurred yet this year
    if (monthDifference < 0 || (monthDifference === 0 && today.getDate() < birthDate.getDate())) {
      age--;
    }

    return age;
  }

  const isValidUrl = (string) => {
    try {
      new URL(string); // If it can be parsed as a URL, it's valid
      return true;
    } catch (_) {
      return false;
    }
  };
  const [flexWrap, setFlexWrap] = useState(false);
  const [isOverflowing, setIsOverflowing] = useState(false);

  const tabsRef = useRef(null); // Ref for the tabs container
  useEffect(() => {
    const checkOverflow = () => {
      if (tabsRef.current) {
        setIsOverflowing(tabsRef.current.scrollWidth > tabsRef.current.clientWidth);
      }
    };

    checkOverflow();
    window.addEventListener("resize", checkOverflow); // Recheck on window resize
    return () => window.removeEventListener("resize", checkOverflow);
  }, [groupedFields]);

  return (
    <div ref={formRef} className="w-full ">
      <div className="flex items-center justify-between " style={{ borderBottom: "2px solid lightgrey" }}>
        <Nav className="nav-primary w-full d-flex justify-between tw-max-w-[92%]" style={{ border: "none" }} tabs>
          <div
            ref={tabsRef} // Attach the ref to the container
            className="flex "
            style={{ flexWrap: flexWrap ? "wrap" : "nowrap", overflow: "hidden" }}
          >
            {Object.keys(groupedFields).map((groupName, index) => (
              <NavItem key={index} style={{ border: "none" }}>
                <NavLink
                  href="#"
                  className={primaryColorTab === index ? "active" : ""}
                  onClick={() => changeDynamicTab(index, groupName)}
                  style={{ flexWrap: "nowrap", whiteSpace: "nowrap" }}
                  title={groupName
                    .split("_") // Split the string by underscores
                    .map((part) => part.charAt(0).toUpperCase() + part.slice(1)) // Capitalize each part
                    .join(" ")}
                >
                  {groupName
                    .split("_") // Split the string by underscores
                    .map((part) => part.charAt(0).toUpperCase() + part.slice(1)) // Capitalize each part
                    .join(" ")}{" "}
                  {/* Join the parts with a space */}
                </NavLink>
              </NavItem>
            ))}
          </div>
        </Nav>
        {isOverflowing && ( // Conditionally render the button if tabs are overflowing
          <div>
            <button onClick={() => setFlexWrap(!flexWrap)}>
              {!flexWrap ? <MdOutlineExpandMore size={30} /> : <MdExpandLess size={30} />}
            </button>
          </div>
        )}
      </div>
      <TabContent activeTab={primaryColorTab} style={{ padding: "20px" }}>
        {Object?.keys(groupedFields).map((groupName, index) => (
          <TabPane tabId={index} key={index}>
            {groupedFields[groupName].slice(0, fieldDisplayLimits[groupName] || 16).map((field, fieldIndex) => {
              if (field.alias === "company" || field.alias == "points") {
                return null;
              }

              return (
                <Fragment key={fieldIndex}>
                  <div className="tw-flex gap-2 tw-items-center">
                    <div className="w-full tw-flex tw-flex-col">
                      {field.alias !== "points" ? (
                        <span style={{ fontSize: "13px", color: "#6C757C", marginTop: "10px" }}>{field.label}</span>
                      ) : null}
                      <ViewFieldsForm
                        field={field}
                        control={control}
                        setValue={setValue}
                        register={register}
                        watch={watch}
                      />
                    </div>
                    <div className="tw-mt-[30px]">
                      {field.type == "email" && watch(field.alias) && (
                        <span style={{ cursor: "pointer" }}>
                          {" "}
                          <a href={`mailto:${watch(field.alias)}`} target="_blank">
                            <BsSendFill size={15} className="hover-scale " />
                          </a>
                        </span>
                      )}
                      {field.alias == "birthday" && watch(field.alias) && (
                        <span style={{ color: "#135f95", cursor: "help" }} title={t("caymland.core.time.years")}>
                          {t("caymland.lead.fields.age") + ":" + calculateAge(watch(field.alias))}
                        </span>
                      )}
                      {field.type == "tel" && watch(field.alias) && (
                        <span style={{ cursor: "pointer" }}>
                          {" "}
                          <a href={`tel:${watch(field.alias)}`} target="_blank">
                            <BsFillTelephoneOutboundFill size={15} className="hover-scale" />
                          </a>
                        </span>
                      )}
                      {field.type == "url" && watch(field.alias) && (
                        <span style={{ cursor: "pointer" }}>
                          <a href={watch(field.alias)} target="_blank" rel="noopener noreferrer">
                            <FaExternalLinkAlt size={15} className="hover-scale " />
                          </a>
                        </span>
                      )}
                      {isValidUrl(watch(field.alias)) && field.type != "url" && (
                        <span style={{ cursor: "pointer" }}>
                          <a href={watch(field.alias)} target="_blank" rel="noopener noreferrer">
                            <FaExternalLinkAlt size={15} className="hover-scale " />
                          </a>
                        </span>
                      )}
                    </div>
                    {dirtyFields[field.alias] && (
                      <div
                        className="d-flex items-center gap-5px "
                        style={{
                          alignSelf: "center",
                          marginTop: "30px",
                          // marginBottom:
                          //   field.type == "select" ||
                          //   field.type == "multiselect" ||
                          //   field.type == "date" ||
                          //   field.type == "lookup"
                          //     ? "0"
                          //     : "20px",
                        }}
                      >
                        <div
                          style={{ cursor: "pointer" }}
                          onClick={(e) => {
                            e.stopPropagation();

                            const fieldName = field.alias;
                            if (!(fieldName in initialValues)) {
                              setValue(fieldName, undefined, { shouldDirty: true });
                            } else {
                              // Reset to its initial value if it exists in initialValues
                              setValue(fieldName, initialValues[fieldName], {
                                shouldDirty: true,
                              });
                            }
                            setEditableField(null);
                          }}
                        >
                          <FaRegTimesCircle color="#f86b4f" size={18} title={t("caymland.core.form.cancel")} />
                        </div>

                        <div
                          style={{ cursor: "pointer" }}
                          onClick={(e) => {
                            e.stopPropagation();
                            updateFields(field.id);
                          }}
                        >
                          <AiOutlineSave color="#135F95" size={20} title={t("caymland.lead.field.save.tooltip")} />
                        </div>
                      </div>
                    )}
                  </div>
                </Fragment>
              );
            })}
            <div className="d-flex justify-center items-center mt-10">
              {groupedFields[groupName].length > 16 && (
                <button
                  className="result-component"
                  style={{ height: "20px", paddingLeft: "10px", paddingRight: "10px" }}
                  onClick={() => toggleFieldDisplay(groupName)}
                >
                  {fieldDisplayLimits[groupName] > 16 ? (
                    <i className="d-flex justify-center items-center" style={{ fontSize: "12px" }}>
                      <KeyboardDoubleArrowUpIcon fontSize="14px" /> {t("caymland.core.show.less")}
                    </i>
                  ) : (
                    <i className="d-flex justify-center items-center" style={{ fontSize: "12px" }}>
                      <KeyboardDoubleArrowDownIcon fontSize="14px" /> {t("caymland.core.show.more")}
                    </i>
                  )}
                </button>
              )}
            </div>
          </TabPane>
        ))}
      </TabContent>
    </div>
  );
};

export default FieldsForm;
