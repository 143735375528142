import React, { Fragment, useState, useEffect } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { Card, CardBody, Nav, NavItem, NavLink, TabContent, TabPane } from "reactstrap";
import Details from "./viewPage/Details";
import Sidebar from "./viewPage/Sidebar";
import PageStats from "./viewPage/PageStats";
import Translations from "./viewPage/Translations";
import Header from "../../../reusableComponents/Header/Header";
import { FaEdit } from "react-icons/fa";
import { FaTrashAlt } from "react-icons/fa";
import { FaRegTimesCircle } from "react-icons/fa";
import Wrapper from "../../../reusableComponents/Wrapper/Wrapper";
import CustomizedAccordions from "../../../reusableComponents/Accordion/Accordion";
import {
  useDeletePageMutation,
  useGetLandingPageDataQuery,
  useGetPageQuery,
  useGetPageViewQuery,
} from "../../../../redux/api/pagesApi";
import Swal from "sweetalert2";
import DetailsSkeleton from "../../../reusableComponents/DetailsSkeleton";
import SidebarSkeleton from "../../../reusableComponents/SidebarSkeleton";
import HeaderSkeleton from "../../../reusableComponents/HeaderSkeleton";
import Description from "../../../reusableComponents/Description/Description";
import { useTranslation } from "react-i18next";

function ViewPage() {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const location = useLocation();
  const id = location?.state?.id || location?.state || useParams().id;

  const [primarycolorTab, setprimarycolorTab] = useState(1);
  const [page, setPage] = useState(null);
  const [extraInfo, setExtraInfo] = useState(null);
  const [openDetails, setOpenDetails] = useState(false);

  const [deletePages] = useDeletePageMutation();
  const { data, isFetching, isLoading } = useGetLandingPageDataQuery(id);
  const { data: getPage, error } = useGetPageQuery(id);
  const { data: getPageView, refetch } = useGetPageViewQuery({
    id,
    dateFrom: localStorage.getItem("fromDate"),
    dateTo: localStorage.getItem("toDate"),
  });

  useEffect(() => {
    if (error) {
      navigate("/pages");
    }
  }, [data, error]);

  useEffect(() => {
    if (data === undefined) return;
    setPage(getPage?.page);
    setExtraInfo({ ...data.extrasData, ...getPageView });
  }, [id, data, getPageView]);

  const handleDateChange = () => {
    const fromDate = localStorage.getItem("fromDate");
    const toDate = localStorage.getItem("toDate");
    refetch({
      id,
      dateFrom: fromDate,
      dateTo: toDate,
    });
  };

  const handleDelete = () => {
    Swal.fire({
      title: t("caymland.badge_contact.form.confirmdelete"),
      text: `${t("caymland.message.delete", { name: page?.title })}`,
      icon: "question",
      confirmButtonText: t("caymland.core.form.delete"),
      cancelButtonText: t("caymland.core.form.cancel"),
      showCancelButton: true,
      customClass: {
        confirmButton: "delete-component swal-custom",
        cancelButton: "result-component swal-custom",
      },
    }).then(async (result) => {
      if (result.isConfirmed) {
        const response = await deletePages(id);
        if (!response?.error) {
          navigate("/pages");
        }
      }
    });
  };

  const buttons = [
    {
      name: "close",
      title: t("caymland.core.form.cancel"),
      icon: <FaRegTimesCircle size={15} />,
      onClick: () => navigate("/pages"),
    },
    {
      name: "edit",
      title: t("caymland.core.permissions.edit"),
      icon: <FaEdit size={15} />,
      onClick: () => navigate(`/pages/edit/${id}`),
      childs: [
        {
          name: "delete",
          title: t("caymland.mailbox.list.action.deleteMessage"),
          icon: <FaTrashAlt size={15} />,
          onClick: () => handleDelete(),
        },
      ],
    },
  ];

  const accordions = [
    {
      name: t("caymland.core.details"),
      component: <Details setOpenDetails={setOpenDetails} openDetails={openDetails} page={page} />,
    },
  ];
  const accordionsDesc = [
    {
      name: t("caymland.core.description"),
      component: <Description data={page} />,
    },
  ];

  return (
    <Fragment>
      {isLoading ? (
        <HeaderSkeleton isView={true} />
      ) : (
        <Header buttons={buttons} title={page?.title} loading={isFetching} data={page} showIsPublished={true} />
      )}
      <Wrapper
        childrenClassOne={"wrapper-childOne"}
        childrenOne={
          isLoading ? (
            <DetailsSkeleton />
          ) : (
            <>
              <Card style={{ boxShadow: "unset" }}>
                <CardBody style={{ padding: "0" }}>
                  <>
                    <PageStats
                      extras={extraInfo}
                      type={page?.emailType}
                      setExtras={setExtraInfo}
                      handleDateChange={handleDateChange}
                      loading={isFetching}
                    />
                    {extraInfo?.translations?.children?.length === 0 ? null : (
                      <div style={{ marginTop: "30px" }}>
                        <Nav className="nav-primary w-full" tabs>
                          <NavItem>
                            <NavLink
                              href="#"
                              className={primarycolorTab === 1 ? "active" : ""}
                              onClick={() => setprimarycolorTab(1)}
                              style={{
                                color: "#313131",
                                textTransform: "capitalize",
                              }}
                            >
                              {t("caymland.core.translations")}
                            </NavLink>
                          </NavItem>
                        </Nav>
                        <TabContent activeTab={primarycolorTab}>
                          <TabPane tabId={1}>
                            <Translations page={page} extras={extraInfo} />
                          </TabPane>
                        </TabContent>
                      </div>
                    )}
                  </>
                </CardBody>
              </Card>
            </>
          )
        }
        childrenClassTwo={"wrapper-childTwoNoMargin"}
        childrenTwo={
          isLoading ? (
            <SidebarSkeleton applyPadding={true} />
          ) : (
            <>
              <CustomizedAccordions accordions={accordions} />
              {page && page?.metaDescription ? (
                <CustomizedAccordions accordions={accordionsDesc} isOpen={true} />
              ) : null}
              <Sidebar page={page} extras={extraInfo} />
            </>
          )
        }
      />
    </Fragment>
  );
}

export default ViewPage;
