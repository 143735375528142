import React, { useEffect, useMemo, useState } from "react";
import Dropzone from "react-dropzone-uploader";
import { t } from "i18next";
import {
  useAddDocumentNoteMutation,
  useDownloadContractNoteDocumentMutation,
} from "../../../../../redux/api/contractsApi";
import CustomTable from "../../../contacts/CustomTable/CustomTable";
import { Controller, useFieldArray } from "react-hook-form";

const Documents = ({ control, noteData, updateNoteData, setValue, currentIndex }) => {
  const [tempFileName, setTempFileName] = useState();

  const { fields, append, remove } = useFieldArray({
    control,
    name: "notes.documents", // This should match the form field name
  });

  const [query, setQuery] = useState({
    pageIndex: 0,
    pageSize: sessionStorage.getItem("pageSize") ? parseInt(sessionStorage.getItem("pageSize"), 10) : 30,
    orderByDir: "DESC",
    orderBy: "uf.dateAdded",
  });

  const [documents, setDocuments] = useState([]);

  const [addDocumentNote] = useAddDocumentNoteMutation();

  useEffect(() => {
    if (noteData) {
      setDocuments(noteData?.documents);
    }
  }, [noteData]);

  const getUploadParams = async ({ file }) => {
    try {
      const formData = new FormData();
      formData.append("file", file);
      setTempFileName(file?.name);

      // Upload file via mutation
      const response = await addDocumentNote(formData).unwrap();
      setDocuments((prevDocuments) => [...prevDocuments, response]);

      const newDocument = {
        id: response.fileId,
        fileName: response.fileName || response.fileId,
        size: response?.size,
        owner: response?.user?.name,
      };

      const updatedNoteData = {
        documents: [...documents, newDocument],
      };
      setValue(`notes[${currentIndex}].documents`, [...documents, newDocument]);

      return { meta: { uploadUrl: response } };
    } catch (error) {
      console.error("File upload failed:", error);
      return { meta: { uploadUrl: null } };
    }
  };

  const handleRemoveDocument = (fileId) => {
    const index = fields.findIndex((field) => field.id === fileId);
    if (index !== -1) {
      remove(index); // Remove from useFieldArray
    }
  };

  const formatSize = (sizeInBytes) => {
    if (!sizeInBytes) return "0 Bytes";

    const units = ["Bytes", "KB", "MB", "GB", "TB"];
    let index = 0;

    let size = sizeInBytes;
    while (size >= 1024 && index < units.length - 1) {
      size /= 1024;
      index++;
    }

    return `${size.toFixed(2)} ${units[index]}`;
  };

  const [downloadFile] = useDownloadContractNoteDocumentMutation();

  const columns = useMemo(() => [
    {
      accessorKey: "fileName",
      accessorFn: (row) => {
        const displayName =
          row?.fileOriginalName?.length > 30
            ? row?.fileOriginalName.substring(0, 30) + "..."
            : row?.fileOriginalName || row?.fileId;

        const handleFileClick = async (e) => {
          e.preventDefault();
          try {
            const response = await downloadFile(row.fileName).unwrap();
            const blob = new Blob([response], { type: response.type });
            const url = window.URL.createObjectURL(blob);
            const a = document.createElement("a");
            a.style.display = "none";
            a.href = url;
            a.download = row.fileOriginalName;
            document.body.appendChild(a);
            a.click();
            document.body.removeChild(a);
            window.URL.revokeObjectURL(url);
          } catch (error) {
            console.error("Failed to download file", error);
          }
        };

        return (
          <a href="#" onClick={handleFileClick}>
            {displayName}
          </a>
        );
      },
      header: t("caymland.core.name"),
      enableColumnActions: false,
    },
    {
      accessorKey: "size",
      accessorFn: (row) => formatSize(row?.size),
      header: t("caymland.focus.form.bar.size"),
      enableColumnActions: false,
    },
    {
      accessorKey: "added",
      accessorFn: (row) => (row.owner ? row?.owner?.modifiedByUser : row?.owner?.createdByUser),
      header: t("caymland.lead.segments.contacts.added"),
      enableColumnActions: false,
    },
    {
      accessorKey: "actions",
      accessorFn: (row) => (
        <button onClick={() => handleRemoveDocument(row.id)} className="btn btn-sm btn-danger">
          {t("Remove")}
        </button>
      ),
      header: t("caymland.actions"),
      enableColumnActions: false,
    },
  ]);

  console.log(documents);

  return (
    <div>
      <Controller
        name="documents"
        control={control}
        render={({ field: { name, onChange, value } }) => (
          <Dropzone
            name={name}
            getUploadParams={getUploadParams}
            onChangeStatus={({ meta, file }, status) => {
              if (status === "done") {
                console.log("File uploaded:", file);
              }
            }}
            accept="*"
            inputContent={t("caymland.asset.drop.file.here")}
            styles={{
              dropzoneActive: { borderColor: "green" },
            }}
          />
        )}
      />

      <CustomTable
        data={documents}
        columns={columns}
        query={query}
        setQuery={() => {}}
        smallHeight
        actions={false}
        linkNavigate={null}
      />
    </div>
  );
};

export default Documents;
