import React, { useEffect, useState } from "react";
import Header from "../../reusableComponents/Header/Header";
import { FaCheckDouble, FaRegTimesCircle } from "react-icons/fa";
import { AiOutlineSave } from "react-icons/ai";
import { t } from "i18next";
import Wrapper from "../../reusableComponents/Wrapper/Wrapper";
import Contract from "./components/Contract";
import Sidebar from "./components/Sidebar";
import Note from "./components/Note";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as Yup from "yup";
import {
  useCreateContractMutation,
  useGetContractByIdQuery,
  useGetContractOptionsQuery,
  useUpdateContractMutation,
} from "../../../redux/api/contractsApi";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import moment from "moment"; // Ensure moment is imported
import HeaderSkeleton from "../../reusableComponents/HeaderSkeleton";
import DetailsSkeleton from "../../reusableComponents/DetailsSkeleton";
import SidebarSkeleton from "../../reusableComponents/SidebarSkeleton";

const ContractsForm = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const id = location?.state?.id || location?.state || useParams().id;

  const { data: contractData, isLoading, refetch } = useGetContractByIdQuery(id, { skip: !id });

  const [postContract] = useCreateContractMutation();
  const [updateContract] = useUpdateContractMutation();

  const validationSchema = Yup.object().shape({
    name: Yup.string().required(true),
    lead: Yup.mixed().required(true),
    status: Yup.mixed().required(true),
    currency: Yup.mixed().required(true),
    // notes: Yup.array().of(
    //   Yup.object().shape({
    //     subject: Yup.string().required(true), // Ensure each note has a subject
    //   })
    // ),
  });

  // React Hook Form setup
  const {
    control,
    handleSubmit,
    reset,
    getValues,
    setValue,
    watch,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(validationSchema),
    defaultValues: {
      name: "",
      lead: null,
      contacts: [],
      type: null,
      status: null,
      currency: null,
      dateValidFrom: null,
      dateValidTo: null,
      continueAutomatically: false,
      checklists: [],
    },
  });

  // Populate form with existing data if editing
  useEffect(() => {
    console.log(contractData);
    if (contractData?.contract) {
      const transformedData = {
        name: contractData?.contract?.name,
        lead: contractData?.contract?.lead?.id,
        user: contractData.contract.user?.id,
        contacts: contractData.contract.contacts?.map((contact) => contact?.lead?.id) || [],
        type: contractData.contract.type?.id,
        status: contractData.contract.status,
        currency: contractData.contract.currency?.id,
        dateValidFrom: new Date(contractData.contract.dateValidFrom),
        dateValidTo: new Date(contractData.contract.dateValidTo),
        continueAutomatically: contractData.contract.continueAutomatically,
        checklists: contractData.contract.checklists?.map((checklist) => checklist?.checklist) || [],
      };
      reset({ ...contractData.contract, ...transformedData });
    }
  }, [contractData, reset]);

  const handleSaveContract = async (data) => {
    // Transform the data
    const transformedData = {
      ...data,
      lead: data.lead?.value || data.lead,
      name: data.name,
      status: data.status?.value || data?.status,
      user: data?.user?.value || data?.user,
      type: data.type?.label || data.type,
      currency: data.currency?.label || data?.currency,
      dateValidFrom: data.dateValidFrom ? moment(data.dateValidFrom).format("YYYY-MM-DD HH:mm") : null,
      dateValidTo: data.dateValidTo ? moment(data.dateValidTo).format("YYYY-MM-DD HH:mm") : null,
      checklists: data.checklists?.map((id) => id) || data?.checklists,
      continueAutomatically: data.continueAutomatically ? 1 : 0,
      comments:
        data.comments?.map((comment) => ({
          message: comment.message,
        })) || [],
      invoices:
        data.invoices?.map((invoice) => ({
          id: id ? invoice.id : null,
          price: invoice.price || 0,
          paymentDate: invoice.paymentDate ? moment(invoice.paymentDate).format("YYYY-MM-DD HH:mm") : "",
          invoiceDeliveredDate: invoice.invoiceDeliveredDate
            ? moment(invoice.invoiceDeliveredDate).format("YYYY-MM-DD HH:mm")
            : "",
          invoiceDelivered: parseInt(invoice.invoiceDelivered, 10) || 0,
        })) || [],
      contacts:
        data.contacts?.map((contact) => ({
          lead: contact,
        })) || [],
      notes:
        data?.notes.map((note) => ({
          ...(Number.isInteger(Number(note.id)) ? { id: note.id } : {}),
          subject: note.subject,
          text: note.text,
          user: note?.user?.value || note?.user,
          dateTime: note.dateTime ? moment(note.dateTime).format("YYYY-MM-DD HH:mm") : null,
          tags: note?.noteTags?.map((item) => item.tag) || [],
          lead: data.lead?.value || data.lead,
          isReminder: note.isReminder ? 1 : 0,
          reminderDateTime: note.reminderDateTime ? moment(note.reminderDateTime).format("YYYY-MM-DD HH:mm") : null,
          action: note?.action?.label || note?.action?.action || "",
          noteEvent: note.noteEvent
            ? {
                name: note.noteEvent.name || null,
                location: note.noteEvent.location || null,
                dateStart: note.noteEvent.dateStart ? moment(note.noteEvent.dateStart).format("YYYY-MM-DD") : null,
                dateEnd: note.noteEvent.dateEnd ? moment(note.noteEvent.dateEnd).format("YYYY-MM-DD") : null,
              }
            : null,
          fileIds: note?.documents?.map((item) => item.id) || [],
        })) || [],
    };

    try {
      let response;
      // Call the API to save the contract
      if (id) {
        response = await updateContract({ body: transformedData, id: id });
        refetch();
      } else {
        response = await postContract(transformedData).unwrap();
        console.log(response);
        navigate(`/contracts/edit/${response.contract.id}`);
      }
    } catch (error) {
      console.error("Error saving contract:", error);
      // Handle error (e.g., show notification)
    }
  };

  const buttons = [
    {
      name: "close",
      title: t("caymland.core.form.close"),
      icon: <FaRegTimesCircle size={15} />,
      onClick: () => navigate("/contracts"),
    },
    {
      name: "apply",
      title: t("caymland.core.form.apply"),
      icon: <FaCheckDouble size={15} />,
      onClick: handleSubmit(handleSaveContract),
    },
    {
      name: "save",
      title: t("caymland.core.form.saveandclose").replace(/&amp;/g, "&"),
      icon: <AiOutlineSave size={15} />,
      onClick: handleSubmit(handleSaveContract),
    },
  ];

  return (
    <>
      {isLoading ? <HeaderSkeleton /> : <Header title={id ? "Edit Contract" : "New Contract"} buttons={buttons} />}

      <Wrapper
        childrenClassOne="w-75"
        childrenOne={
          isLoading ? (
            <DetailsSkeleton applyPadding />
          ) : (
            <>
              <div className="tw-flex tw-w-full tw-gap-4">
                <Contract control={control} errors={errors} watch={watch} />
                <Note
                  control={control}
                  errors={errors}
                  watch={watch}
                  noteData={contractData?.contract?.notes}
                  getValues={getValues}
                  setValue={setValue}
                  reset={reset}
                />
              </div>
            </>
          )
        }
        childrenClassTwo="wrapper-childTwo"
        childrenTwo={isLoading ? <SidebarSkeleton /> : <Sidebar control={control} watch={watch} errors={errors} />}
      />
    </>
  );
};

export default ContractsForm;
