import React, { useEffect, useState } from "react";
import DynamicContentTab from "./DynamicContentTab";
import { Nav, NavItem, NavLink } from "reactstrap";
import Wrapper from "../../../../reusableComponents/Wrapper/Wrapper";
import { useGetDynamicContentQuery } from "../../../../../redux/api/dwcApi";
import { t } from "i18next";
import { v4 as uuidv4 } from "uuid";

const DynamicTabsContainer = ({ mainTabs, setMainTabs }) => {
  const [activeMainTab, setActiveMainTab] = useState(0);
  const [activeSubTab, setActiveSubTab] = useState(1);
  const [loading, setLoading] = useState(false);
  const [fields, setFields] = useState([]);

  console.log(mainTabs);

  const { data } = useGetDynamicContentQuery();

  useEffect(() => {
    if (data) {
      fetchFilterOptions();
    }
  }, [data]);

  const fetchFilterOptions = async () => {
    setLoading(true);
    const response = data;
    if (!response.errors) {
      const filters = response;

      const generateFilterObject = (filterName, filterData) => {
        return {
          label: filterName,
          options: Object.entries(filterData).map(([key, value]) => ({
            name: key,
            label: value.label,
            object: value.object,
            operators: Object.entries(value?.operators).map(([key2, value2]) => ({
              name: key2,
              label: value2,
            })),
            ...value.properties,
            group: value.object,
          })),
        };
      };

      const tempFields = Object.entries(filters).map(([key, value]) => generateFilterObject(key, value));

      setFields([...tempFields]);
      setLoading(false);
    } else {
      console.error(`Unexpected response status: ${response.status}`);
      setLoading(false);
    }
  };

  const selectMainTab = (index) => {
    setActiveMainTab(index);
    setActiveSubTab(1);
  };

  const selectSubTab = (index, tabName) => {
    setActiveSubTab(index);
    if (tabName === t("caymland.mailbox.list.action.new")) {
      setActiveSubTab(mainTabs[activeMainTab].subtabs.length - 1);
    }
  };

  const addNewMainTab = () => {
    const highestNumber = mainTabs.reduce((max, tab) => {
      const match = tab.name.match(/\d+/);
      return match ? Math.max(max, parseInt(match[0], 10)) : max;
    }, 0);

    const newMainTabName = `${t("caymland.core.dynamicContent.new", { number: highestNumber + 1 })}`;

    let newSubtabs = [
      {
        id: uuidv4(), // Generate unique ID
        name: t("caymland.core.form.default"),
        inputValue: "",
        selectValues: {
          combinator: "and",
          rules: [],
        },
      },
      {
        id: uuidv4(), // Generate unique ID
        name: `${t("caymland.core.dynamicContent.tab")} 1`,
        inputValue: "",
        selectValues: {
          combinator: "and",
          rules: [],
        },
      },
    ];

    if (mainTabs.length > 0) {
      newSubtabs.unshift({
        id: uuidv4(), // Generate unique ID
        name: t("caymland.mailbox.list.action.new"),
        inputValue: "",
        selectValues: {
          combinator: "and",
          rules: [],
        },
      });
    }

    const newMainTab = {
      name: newMainTabName,
      subtabs: newSubtabs,
    };
    setMainTabs([...mainTabs, newMainTab]);
    setActiveMainTab(mainTabs.length);
    setActiveSubTab(1);
  };

  const addNewSubTab = () => {
    if (!mainTabs[activeMainTab]?.subtabs) return;

    const currentSubtabs = mainTabs[activeMainTab].subtabs;
    const highestNumber = currentSubtabs.reduce((max, subtab) => {
      const match = subtab.name.match(/\d+/);
      return match ? Math.max(max, parseInt(match[0], 10)) : max;
    }, 0);

    const newVariationName = `${t("caymland.core.dynamicContent.tab")} ${highestNumber + 1}`;

    const newSubtabs = [
      ...currentSubtabs,
      {
        id: Date.now(), // Generate unique ID
        name: newVariationName,
        inputValue: "",
        selectValues: {
          combinator: "and",
          rules: [],
        },
      },
    ];

    setMainTabs((prevMainTabs) => {
      const newMainTabs = [...prevMainTabs];
      newMainTabs[activeMainTab].subtabs = newSubtabs;
      return newMainTabs;
    });
  };

  const deleteSubTab = () => {
    if (mainTabs[activeMainTab].subtabs[activeSubTab].name === "Default") {
      setMainTabs(mainTabs.filter((_, index) => index !== activeMainTab));
      setActiveMainTab(0);
      setActiveSubTab(1);
    } else {
      const newMainTabs = [...mainTabs];
      newMainTabs[activeMainTab].subtabs.splice(activeSubTab, 1);
      setMainTabs(newMainTabs);
      if (activeSubTab >= newMainTabs[activeMainTab].subtabs.length) {
        setActiveSubTab(newMainTabs[activeMainTab].subtabs.length - 1);
      } else {
        setActiveSubTab(1);
      }
    }
  };

  const updateInputValue = (tabIndex, subTabIndex, newInputValue) => {
    setMainTabs((prevMainTabs) => {
      const newMainTabs = [...prevMainTabs];
      newMainTabs[tabIndex].subtabs[subTabIndex].inputValue = newInputValue;
      return newMainTabs;
    });
  };

  return (
    <>
      <Wrapper
        childrenClassOne={"wrapper-childOne"}
        childrenOne={
          <>
            {mainTabs.map((instance, index) => (
              <div key={index} style={{ display: index === activeMainTab ? "block" : "none" }}>
                <DynamicContentTab
                  activeSubTab={activeSubTab}
                  selectSubTab={selectSubTab}
                  activeMainTab={activeMainTab}
                  instance={instance}
                  addNewSubTab={addNewSubTab}
                  deleteSubTab={deleteSubTab}
                  updateInputValue={updateInputValue}
                  setMainTabs={setMainTabs}
                  loading={loading}
                  fields={fields}
                />
              </div>
            ))}
          </>
        }
        childrenClassTwo={"wrapper-childTwo"}
        childrenTwo={
          <>
            <Nav className="nav-primary w-full flex" tabs>
              <NavItem style={{ borderBottom: "none", width: "100%", textAlign: "start" }}>
                <NavLink
                  href="#"
                  className="result-component"
                  style={{
                    marginBottom: "20px",
                    maxWidth: "100%",
                  }}
                  onClick={addNewMainTab}
                >
                  {`${t("caymland.core.form.add")} ${t("caymland.core.form.new")} ${t(
                    "caymland.focus.tab.focus_content"
                  )}`}
                </NavLink>
              </NavItem>
            </Nav>
            <Nav className="nav-primary w-full flex" tabs>
              {mainTabs.map((tab, index) => (
                <NavItem key={index} style={{ borderBottom: "none", width: "100%", textAlign: "start" }}>
                  <NavLink
                    href="#"
                    className={activeMainTab === index ? "active" : ""}
                    style={{
                      display: "flex",
                      justifyContent: "flex-start",
                      alignItems: "center",
                      color: "#313131",
                      textTransform: "capitalize",
                    }}
                    onClick={() => selectMainTab(index)}
                  >
                    {tab.name}
                  </NavLink>
                </NavItem>
              ))}
            </Nav>
          </>
        }
      />
    </>
  );
};

export default DynamicTabsContainer;
