import React, { useEffect, useMemo, useRef, useState } from "react";
import { Card, CardBody, Nav, NavItem, NavLink } from "reactstrap";
import { MdRefresh } from "react-icons/md";
import Loading from "../../reusableComponents/loading";
import "react-dropzone-uploader/dist/styles.css";
import { Success } from "../../reusableComponents/Alerts";
import { useNavigate, useLocation, useParams } from "react-router-dom";
import CoreTab from "./CoreTab";
import TomasTab from "./TomasTab";
import SocialTab from "./SocialTab";
import Swal from "sweetalert2";
import DynamicTab from "./DynamicTab";
import md5 from "blueimp-md5";
import { FaCheckDouble, FaRegTimesCircle } from "react-icons/fa";
import { AiFillWarning, AiOutlineSave } from "react-icons/ai";
import Header from "../../reusableComponents/Header/Header";
import Wrapper from "../../reusableComponents/Wrapper/Wrapper";
import { useGetContactFieldsQuery } from "../../../redux/api/fieldsApi";
import { useForm } from "react-hook-form";
import {
  useAddContactMutation,
  useGetContactByIdQuery,
  useUpdateContactsMutation,
} from "../../../redux/api/contactsApi";
import { useTranslation } from "react-i18next";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";

function AddNew() {
  const { t } = useTranslation();
  const [contactFields, setContactFields] = useState();
  const [customFields, setCustomFields] = useState([]);
  const [loading, setLoading] = useState(false);
  const [uniqueFieldGroups, setUniqueFieldGroups] = useState([]);
  const [currentTab, setCurrentTab] = useState("core");
  const [show, setShow] = useState(false);
  const [message, setMessage] = useState("");
  const [contactImg, setContactImg] = useState();
  const [initialValues, setInitialValues] = useState();
  const [isFormChanged, setIsFormChanged] = useState(false);
  const [backgorundToast, setBackgroundToast] = useState("");
  const [disabled, setDisabled] = useState(false);

  const navigate = useNavigate();
  const location = useLocation();

  const id = location?.state?.id || location?.state || useParams().id;
  const isEdit = location.pathname.includes("edit");

  const navBack = () => {
    navigate("/contacts");
  };

  const { data } = useGetContactFieldsQuery();

  const { data: contact, refetch } = useGetContactByIdQuery(id, { skip: !id });

  const [addContact, { isLoading: isAddLoading }] = useAddContactMutation();
  const [updateContact, { isLoading: isEditLoading }] = useUpdateContactsMutation();
  const isSaving = isEditLoading || isAddLoading;

  const firstName = contact?.data?.fields?.all?.firstname ? contact.data?.fields?.all?.firstname : "";
  const lastName = contact?.data?.fields?.all?.lastname ? contact.data?.fields?.all?.lastname : "";

  useEffect(() => {
    if (data?.data) {
      setContactFields(data.data);
      retrieveFields();
      retrieveGroups();
    }
  }, [data]);

  useEffect(() => {
    if (contact?.data && id) {
      const mergedData = {
        ...contact.data.fields.all,
        stage: contact?.data?.stage?.id || null,
        tags: contact?.data?.tags?.map((tag) => tag?.tag) || [],
        owner: contact?.data?.owner?.id,
        companies: contact?.data?.fields?.companies?.map((company) => ({
          value: company.company_id,
          label: company.companyname,
        })),
      };
      reset(mergedData);
      setInitialValues(mergedData);
    }
  }, [contact]);

  const requiredFields = customFields.filter((item) => item.isRequired && item.isVisible && item.isPublished);

  const generateYupSchema = (fields) => {
    const schemaFields = fields.reduce((acc, field) => {
      let validator;
      switch (field.type) {
        case "boolean":
          validator = yup.number().required("This field is required");
          break;
        case "email":
          validator = yup
            .string()
            .email(t("caymland.core.valid.email"))
            .required(t("caymland.core.fieldrequired"))
            .matches(/^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)+\d*$/, {
              message: t("caymland.core.valid.email"),
            });
          break;
        default:
          validator = yup.string().required(t("caymland.core.fieldrequired"));
      }
      acc[field.alias] = validator;
      return acc;
    }, {});

    return yup.object().shape(schemaFields);
  };

  const validationSchema = generateYupSchema(requiredFields);

  //USE-FORMS

  const {
    control,
    handleSubmit,
    watch,
    reset,
    setValue,
    getValues,
    formState: { errors },
  } = useForm({ resolver: yupResolver(validationSchema) });
  const formValues = watch(); // Watches all fields

  useEffect(() => {
    // Assuming `initialValues` is an object that contains the original form data
    const isChanged = JSON.stringify(formValues) !== JSON.stringify(initialValues);
    setIsFormChanged(isChanged);
  }, [formValues]);
  useEffect(() => {
    if (!_.isEmpty(errors)) {
      console.error(errors);
      if (errors) {
        setShow(true);
        setMessage(t("caymland.core.error.not.valid"));
        setBackgroundToast("2");
        setTimeout(() => {
          setMessage("");
          setShow(false);
          setBackgroundToast("1");
        }, 3000);
      }
    }
  }, [errors]);
  //FIELDS
  const retrieveFields = async () => {
    setLoading(true);
    let orderFields = [...data.data];

    if (orderFields !== null) {
      setCustomFields(
        orderFields.sort((a, b) => a.order - b.order).filter((x) => x.isVisible === true || x.isRequired === true)
      );
    } else {
      setCustomFields([]);
    }

    setLoading(false);
  };
  //TABS
  const retrieveGroups = () => {
    setLoading(true);
    let customFields = [...data.data];
    let groups = customFields.map((x) => {
      if (x.isPublished === true || x.isRequired === true) {
        return x.group;
      }
    });
    let uniqueGroups = [...new Set(groups.filter(Boolean))];

    const staticTabs = ["core", "social", "zusatzinformationen"];
    const orderedTabs = staticTabs.filter((tab) => uniqueGroups.includes(tab));
    const otherTabs = uniqueGroups.filter((tab) => !staticTabs.includes(tab));
    uniqueGroups = [...orderedTabs, ...otherTabs];

    setUniqueFieldGroups(uniqueGroups);
    setLoading(false);
  };

  function scrollToTab(tabId, tabTitle) {
    const tabElement = document.getElementById(tabId);
    if (tabElement) {
      tabElement.scrollIntoView({ behavior: "smooth" });
      setCurrentTab(tabTitle);
    }
  }
  const getErrorTabs = () => {
    const errorsList = Object.keys(errors); // Assuming `errors` comes from useForm
    const errorTabs = contactFields?.filter((field) => errorsList.includes(field.alias)).map((field) => field.group);

    // Deduplicate tabs
    return [...new Set(errorTabs)];
  };

  const errorTabs = getErrorTabs(); // Get tabs with errors

  const DetailTabsCard = () => {
    return (
      <div className="flex items-center justify-between">
        <Nav className="nav-primary w-full" tabs>
          {uniqueFieldGroups?.map((tabTitle, index) => {
            const hasError = errorTabs.includes(tabTitle);
            return (
              <NavItem key={index}>
                <NavLink
                  href="#"
                  className={tabTitle === currentTab ? "active" : ""}
                  onClick={() => scrollToTab(`tab-${index}`, tabTitle)}
                  style={{
                    color: "#313131",
                    textTransform: "capitalize",
                    cursor: "pointer",
                  }}
                  id={`tab-${index}`}
                >
                  {tabTitle.replace(/[^a-zA-Z0-9]/g, " ")}
                  {hasError && <AiFillWarning style={{ marginLeft: "5px", color: "red" }} />}
                </NavLink>
              </NavItem>
            );
          })}
        </Nav>
      </div>
    );
  };
  //ADD/EDIT FUNCTION
  const handleAddContact = async (data, close = false) => {
    const dataToSend = { ...data, companies: data?.companies?.map((company) => company.value) || [] };
    const response = _.has(dataToSend, "id") ? await updateContact(dataToSend) : await addContact(dataToSend);

    if (!response?.error) {
      Success(
        `${t("caymland.webhook.label.success")} ${t("caymland.message.type.successfully", {
          type: id ? "edited" : "created",
        })}`
      ).then((result) => {
        if (result.isConfirmed) {
          navigate(`/contacts/${close ? "view" : "edit"}/${response.data.contact.id}`);
        }
      });
      if (id) refetch();
      reset(response.data.contact.fields.all);
    } else {
      setShow(true);
      setMessage(response.error.data.errors[0].message);
      setBackgroundToast("2");
      setTimeout(() => {
        setShow(false);
        setMessage("");
        setBackgroundToast("1");
      }, 3000);
    }
  };

  const handleCancel = () => {
    if (isFormChanged && id) {
      Swal.fire({
        title: t("caymland.badge_contact.form.confirmdelete"),
        text: t("caymland.validation.message.changes"),
        icon: "question",
        confirmButtonText: t("caymland.core.form.yes"),
        cancelButtonText: t("caymland.core.form.cancel"),
        showCancelButton: true,
        customClass: {
          confirmButton: "swal-custom confirm-component",
          cancelButton: "swal-custom result-component",
        },
      }).then((result) => {
        if (result.isConfirmed) {
          navBack();
        }
      });
    } else {
      navBack();
    }
  };

  function getGravatarURL(email, size = 80) {
    const defaultImageURL = "https://cdn-icons-png.flaticon.com/512/149/149071.png";

    if (!email) {
      return defaultImageURL;
    }
    const hash = md5(email.toLowerCase().trim());
    return `https://www.gravatar.com/avatar/${hash}?s=${size}&d=${encodeURIComponent(defaultImageURL)}`;
  }

  let imageURL = getGravatarURL(contactImg);

  const buttons = [
    {
      name: "close",
      title: t("caymland.core.form.cancel"),
      icon: <FaRegTimesCircle size={15} />,
      onClick: () => handleCancel(),
    },
    {
      name: "save",
      title: t("caymland.core.form.saveandclose")?.replace(/&amp;/g, "&"),
      icon: <AiOutlineSave size={15} />,
      isDisabled: disabled,
      onClick: handleSubmit((data) => handleAddContact(data, true)),
    },
    {
      name: "apply",
      title: t("caymland.core.form.apply"),
      icon: <FaCheckDouble size={15} />,
      isDisabled: disabled,
      onClick: handleSubmit((data) => handleAddContact(data)),
    },
  ];

  return (
    <>
      <Header
        buttons={buttons}
        loading={isSaving}
        background={backgorundToast}
        title={`${
          id
            ? t("caymland.lead.lead.header.edit", { name: `${firstName} ${lastName}` })
            : t("caymland.lead.lead.header.new")
        }`}
        alertMessage={message}
        showAlert={show}
      />
      {customFields.length > 0 ? (
        <></>
      ) : (
        <>
          <MdRefresh
            className="cursor-pointer"
            title="Refresh fields"
            onClick={() => retrieveFields()}
            color="#135f95"
            size={18}
          />
        </>
      )}
      <>
        <Wrapper
          childrenClassOne={"wrapper-childTwo20"}
          childrenOne={
            <>
              <div className="custom-card m-0" style={{ height: "auto", borderRadius: "unset" }}>
                <div className="custom-card-header" style={{ borderRadius: "4px", height: "38px" }}>
                  <h5 className="m-0" style={{ fontWeight: "600" }}>
                    {t("caymland.lead.lead.field.preferred_profile")}
                  </h5>
                </div>
                <div className="custom-card-body">
                  <div className="user-image grid gap-20">
                    <div className="avatar py-3">
                      <img
                        className="pro"
                        alt="img"
                        src={imageURL}
                        data-intro="This is Profile image"
                        width="75%"
                        style={{ display: "block", margin: "0 auto" }}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </>
          }
          childrenClassTwo={"wrapper-childOne80"}
          childrenTwo={
            <>
              <Card style={{ boxShadow: "unset" }}>
                <CardBody style={{ padding: "0" }}>
                  <DetailTabsCard />
                  {currentTab === "core" ? (
                    <CoreTab
                      setContactImg={setContactImg}
                      contactFields={contactFields}
                      control={control}
                      watch={watch}
                      getValues={getValues}
                      setValue={setValue}
                      refetch={refetch}
                      setDisabled={setDisabled}
                      errors={errors}
                    />
                  ) : currentTab === "social" ? (
                    <SocialTab contactFields={contactFields} control={control} errors={errors} watch={watch} />
                  ) : currentTab === "zusatzinformationen" ? (
                    <TomasTab contactFields={contactFields} control={control} errors={errors} watch={watch} />
                  ) : (
                    uniqueFieldGroups.includes(currentTab) && (
                      <DynamicTab
                        currentTab={currentTab}
                        contactFields={contactFields}
                        control={control}
                        errors={errors}
                        watch={watch}
                      />
                    )
                  )}
                </CardBody>
              </Card>
            </>
          }
        />
      </>
    </>
  );
}

export default AddNew;
