import React from "react";
import { useParams } from "react-router-dom";
import { useRemoveDoNotContactMutation } from "../../../redux/api/contactsApi";
import { Error, Success } from "../../reusableComponents/Alerts";
import { t } from "i18next";

function DoNotContact({ data, setModalOptions }) {
  console.log(data);

  // Reason mapping with labels, values, and corresponding colors
  const reasonMapping = [
    { label: t("caymland.preferences.reason.single.opt.in"), value: 0, color: "#04B76B" },
    { label: t("caymland.preferences.reason.double.opt.in"), value: 10, color: "#04B76B" },
    { label: t("caymland.preferences.reason.unsubscribed"), value: 1, color: "#f86b4f" },
    { label: t("caymland.preferences.reason.bounced"), value: 2, color: "#fdb933" },
    {
      label: t("caymland.preferences.reason.manual") + " " + t("caymland.preferences.reason.unsubscribed"),
      value: 3,
      color: "#f86b4f",
    },
    { label: t("caymland.preferences.reason.soft"), value: 4, color: "#fdb933" },
    { label: t("caymland.preferences.reason.blacklist"), value: 5, color: "black" },
  ];

  // Function to get the badge for the specific reason
  const getBadge = (reasonValue) => {
    const reason = reasonMapping.find((r) => r.value === reasonValue);
    if (!reason) return null;

    return (
      <span
        key={reason.value}
        style={{
          backgroundColor: reason.color,
          color: "white",
          padding: "5px 10px",
          borderRadius: "8px",
          marginRight: "10px",
          cursor: "pointer",
        }}
        onClick={() =>
          setModalOptions((prev) => ({
            ...prev,
            isOpen: true,
          }))
        }
      >
        {reason.label}
      </span>
    );
  };

  // Check if data.contact exists
  if (!data || !data.contact) {
    return <div></div>;
  }

  // Filter for doNotContact and optInContact items related to "email"
  const doNotContactItems = data.contact.doNotContact?.filter((item) => item.channel === "email") || [];
  const optInContactItems = data.contact.optInContact?.filter((item) => item.channel === "email") || [];

  // If optInContact is empty, add default single-opt-in
  const fallbackSingleOptIn = [
    {
      id: "default-single-opt-in",
      channel: "email",
      reason: 0, // single-opt-in reason value
    },
  ];

  // Determine the items to render
  const itemsToRender =
    doNotContactItems.length > 0
      ? doNotContactItems
      : optInContactItems.length > 0
      ? optInContactItems
      : fallbackSingleOptIn;

  return (
    <div>
      {itemsToRender.map((item) => (
        <div key={item.id}>
          {/* Generate a badge for each reason */}
          {getBadge(item.reason)}
        </div>
      ))}
    </div>
  );
}

export default DoNotContact;
