import React, { Fragment, useEffect, useState } from "react";
import NewCategoryPopUp from "../../reusableComponents/newCategoryPopUp/NewCategoryPopUp";
import { Controller } from "react-hook-form";
import { Checkbox, Select, DatePicker, TimePicker, Text, TextArea } from "../../reusableComponents/Inputs";
import { retrieveCategories } from "../../reusableComponents/retriveCategoriesByBundle";
import { categoriesApi, useAddCategoryMutation } from "../../../redux/api/categoriesApi";
import { t } from "i18next";
import { CategoryModalData } from "../../reusableComponents/ModalGenerator/ModalGeneratorData";
import ModalGenerator from "../../reusableComponents/ModalGenerator/ModalGenerator";

function Sidebar({ control, watch, setValue, errors }) {
  const [modalOptions, setModalOptions] = useState({
    isOpen: false,
    title: "",
    type: "campaign",
  });
  const [newItem, setNewItem] = useState();
  const [triggerCategories] = categoriesApi.useLazyGetCategoriesOptionsQuery();

  const [addCategory] = useAddCategoryMutation();

  useEffect(() => {
    if (newItem) {
      setValue("category", newItem);
    }
  }, [newItem]);
  const intervals = [
    { label: t("caymland.campaign.interval_execution.10_minutes"), value: "PT10M" },
    { label: t("caymland.campaign.interval_execution.30_minutes"), value: "PT30M" },
    { label: t("caymland.campaign.interval_execution.1_hour"), value: "PT60M" },
    { label: t("caymland.campaign.interval_execution.6_hours"), value: "PT6H" },
    { label: t("caymland.campaign.interval_execution.12_hours"), value: "PT12H" },
    { label: t("caymland.campaign.interval_execution.1_day"), value: "P1D" },
  ];

  const handleExecution = (e) => {
    if (!e.target.checked) {
      setValue("intervalExecution", null);
      setValue("timeExecution", null);
    }

    return e;
  };

  return (
    <Fragment>
      <ModalGenerator
        modalOptions={modalOptions}
        setModalOptions={setModalOptions}
        content={CategoryModalData}
        addItem={addCategory}
        setNewItem={setNewItem}
      />
      <Controller
        control={control}
        name={"name"}
        render={({ field: { name, onChange, value } }) => (
          <Text
            name={name}
            value={value ?? ""}
            onChange={onChange}
            label={t("caymland.core.name")}
            required={true}
            errors={errors && errors[name]}
          />
        )}
      ></Controller>
      <Controller
        control={control}
        name="description"
        render={({ field: { name, onChange, value } }) => (
          <TextArea
            name={name}
            value={value ?? ""}
            onChange={onChange}
            label={t("caymland.lead.field.companydescription")}
          />
        )}
      />

      <Controller
        control={control}
        name="category"
        render={({ field: { name, value, onChange } }) => (
          <Select
            name={name}
            label={t("caymland.core.category")}
            options={retrieveCategories}
            trigger={triggerCategories}
            bundle="campaign"
            value={value}
            isCreatable
            onChange={(selectedOptions) => {
              onChange(selectedOptions?.value);
              if (selectedOptions?.value === "create_new") {
                setModalOptions((prev) => ({
                  ...prev,
                  isOpen: true,
                }));
              }
            }}
          />
        )}
      />
      <div className="tw-flex tw-flex-col tw-gap-[10px]">
        <Controller
          control={control}
          name="allowRestart"
          render={({ field: { name, value, onChange } }) => (
            <Checkbox
              name={name}
              value={value}
              onChange={onChange}
              label={t("caymland.campaign.allow_restart")}
              tooltip={""}
            />
          )}
        />
        <Controller
          control={control}
          name="noLog"
          render={({ field: { name, value, onChange } }) => (
            <Checkbox
              name={name}
              value={value}
              onChange={onChange}
              label={t("caymland.campaign.no_log")}
              tooltip={t("caymland.campaign.no_log.tooltip")}
            />
          )}
        />
        <Controller
          control={control}
          name="isPublished"
          render={({ field: { name, value, onChange } }) => (
            <Checkbox name={name} value={value} onChange={onChange} label={t("caymland.core.form.published")} />
          )}
        />
      </div>
      <>
        <Controller
          control={control}
          name="publishUp"
          render={({ field: { name, value, onChange } }) => (
            <DatePicker
              label={t("caymland.core.form.publishup")}
              name={name}
              value={value}
              maxDate={watch("publishDown")}
              onChange={onChange}
            />
          )}
        />
        <Controller
          control={control}
          name="publishDown"
          render={({ field: { name, value, onChange } }) => (
            <DatePicker
              label={t("caymland.core.form.publishdown")}
              name={name}
              value={value}
              minDate={watch("publishUp")}
              onChange={onChange}
            />
          )}
        />
      </>
      <Controller
        control={control}
        name="execution"
        render={({ field: { name, value, onChange } }) => (
          <Checkbox
            name={name}
            value={value}
            onChange={(e) => onChange(handleExecution(e))}
            label={t("caymland.core.range.execute")}
          />
        )}
        style={{
          opacity: !!watch("execution") ? 1 : 0,
          transition: "opacity 0.6s ease-out",
        }}
      />
      {watch("execution") && (
        <Controller
          control={control}
          name="intervalExecution"
          render={({ field: { name, value, onChange } }) => (
            <Select
              name={name}
              label={t("caymland.core.form.interval")}
              options={intervals}
              value={value}
              onChange={onChange}
              required={true}
            />
          )}
        />
      )}
      {(watch("intervalExecution")?.value == "P1D" || watch("intervalExecution") == "P1D") && (
        <Controller
          control={control}
          name="timeExecution"
          render={({ field: { name, value, onChange } }) => (
            <TimePicker
              label={t("caymland.core.form.time.execute")}
              name={name}
              value={value}
              onChange={onChange}
              required={true}
            />
          )}
        />
      )}
    </Fragment>
  );
}

export default Sidebar;
