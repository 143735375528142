import React, { useEffect, useRef, useState, forwardRef, useImperativeHandle } from "react";
import EmailEditor from "react-email-editor";
import { Nav, NavItem, NavLink, TabContent, TabPane } from "reactstrap";
import DynamicTabsContainer from "./DynamicTabsContainer";
import Button from "../../../../reusableComponents/Buttons/Button";
import { t } from "i18next";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import VisibilityIcon from "@mui/icons-material/Visibility";
import DisplayConditionModal from "../DisplayConditionModal";
import { useGetSegmentsOptionsQuery } from "../../../../../redux/api/segmentsApi";
import { useGetBuilderConfigQuery } from "../../../../../redux/api/usersApi";
import StyleOutlinedIcon from "@mui/icons-material/StyleOutlined";
import CustomJson from "./CustomJson";
import CustomHtml from "./CustomHtml";
import { useSelector } from "react-redux";
import { v4 as uuidv4 } from "uuid";
import { emailsApi, useGetEmailVersionsQuery } from "../../../../../redux/api/emailsApi";
import moment from "moment";
import { Select } from "../../../../reusableComponents/Inputs";

const Builder = forwardRef(({ setModifiedData, modifiedData, setIsOpenModal, isOpenModal, apply, themes }, ref) => {
  const location = useLocation();
  const id = location?.state?.id || location?.state || useParams().id;
  const [primarycolorTab, setPrimaryColorTab] = useState(1);
  const emailEditorRef = useRef(null);
  const [shouldApply, setShouldApply] = useState(false);
  const [applyBuilder, setApplyBuilder] = useState("");
  const [openModal, setOpenModal] = useState(false);
  const [initalDisplayContent, setInitalDisplayContent] = useState([]);
  const [rightColumnValue, setRightColumnValue] = useState([]);
  const [applyDCond, setApplyDCond] = useState(false);
  const { data: segmentOptions } = useGetSegmentsOptionsQuery("limit=-1");


  const isEdit = location.pathname.includes("edit");

  const { data } = useGetBuilderConfigQuery("email");

  const [mainTabs, setMainTabs] = useState([
    {
      name: `${t("caymland.core.dynamicContent.new", { number: 1 })}`,
      subtabs: [
        {
          name: t("caymland.mailbox.list.action.new"),
          inputValue: "",
          selectValues: {
            combinator: "and",
            rules: [],
          },
        },
        {
          name: t("caymland.core.form.default"),
          inputValue: "",
          selectValues: {
            combinator: "and",
            rules: [],
          },
        },
        {
          name: `${t("caymland.core.dynamicContent.tab", { number: 1 })} `,
          inputValue: "",
          selectValues: {
            combinator: "and",
            rules: [],
          },
        },
      ],
    },
  ]);

  useImperativeHandle(ref, () => ({
    applyAndCloseTemplate: (params) => applyAndCloseTemplate(params),
    transformData: () => transformData(mainTabs),
  }));

  function generateId() {
    return "r-" + Math.random().toFixed(15).substring(2);
  }

  useEffect(() => {
    const dynamicContentMergeTags = transformMainTabsToMergeTags(mainTabs);
    const newMergeTags = {
      ...data?.mergeTags,
      dynamicContent: {
        name: "Dynamic Content",
        mergeTags: dynamicContentMergeTags,
      },
    };
  }, [mainTabs, data?.mergeTags]);

  const callbackRef = useRef(null);

  useEffect(() => {
    if (emailEditorRef?.current && data) {
      emailEditorRef?.current?.editor?.registerCallback("displayCondition", (data, done) => {
        setInitalDisplayContent(data.value);
        setOpenModal(true);

        // Store the callback reference for later manual triggering
        callbackRef.current = done;

        if (applyDCond) {
          handleConfirm(data, done);
          setApplyDCond(false);
        }
      });
    }
  }, [applyDCond, data, emailEditorRef]);

  const url = window.location.hostname;
  const isCaymLandDomain = url.endsWith("caym.land");

  const subdomainParts = url.split(".");
  const subdomain = subdomainParts[0];
  const api = isCaymLandDomain ? `https://${subdomain}.caymland.dev` : process.env.REACT_APP_DEV_URL;
  const token = useSelector((state) => state.auth.token);

  const handleConfirm = (data, done) => {
    // Get the selected segments
    const selectedSegments = rightColumnValue.map((item) => item.value);

    // Generate a random type string if none exists in the data
    const type = data?.type?.length > 0 ? data.type : Math.random().toString(36).substring(7);

    // Call the done function with the appropriate data
    done({
      type: type,
      label: `${t("caymland.core.segment.filter.builder.label", {
        ids: selectedSegments.join(", "),
      })}`,
      value: selectedSegments,
      description: `${t("caymland.core.segment.filter.builder.desc", { ids: selectedSegments.join(", ") })}`,
      before: `{blockfilter segment="${selectedSegments.join(", ")}"}`,
      after: `{/blockfilter}`,
    });
  };

  useEffect(() => {
    if (applyDCond && callbackRef.current) {
      const data = {
        type: "",
        value: initalDisplayContent, // Preserve the initial display content
      };

      handleConfirm(data, callbackRef.current);
    }
  }, [applyDCond]);

  useEffect(() => {
    // Initialize rightColumnValue with the matching segments
    if (initalDisplayContent && segmentOptions) {
      const initialSelectedSegments = segmentOptions?.filter((segment) => initalDisplayContent.includes(segment.value));
      setRightColumnValue(initialSelectedSegments);
    }
  }, [initalDisplayContent]);

  const transformMainTabsToMergeTags = (mainTabs) => {
    return mainTabs.map((tab) => ({
      name: tab.name,
      value: `{dynamiccontent="${tab.name}"}`,
    }));
  };

  useEffect(() => {
    const newData = modifiedData?.dynamicContent?.map((item, itemIndex) => {
      const defaultContent = item.content ? item.content : "";
      let subtabs = [
        {
          name: t("caymland.mailbox.list.action.new"),
          inputValue: "",
          selectValues: {
            combinator: "and",
            rules: [],
          },
        },
        {
          name: t("caymland.core.form.default"),
          inputValue: defaultContent,
          selectValues: {
            combinator: "and",
            rules: [],
          },
        },
      ];

      const variationSubtabs = item?.filters?.map((filter, filterIndex) => {
        return {
          name: `${t("caymland.core.dynamicContent.tab")} ${filterIndex + 1}`,
          id: uuidv4(), // Generate unique ID
          inputValue: filter.content,
          selectValues: {
            combinator: "and",
            rules: filter?.filters?.map((subFilter) => {
              return {
                field: {
                  field: subFilter?.field || null,
                  id: subFilter?.field || null,
                  input: subFilter?.type || null,
                  object: subFilter?.object || null,
                  operators: subFilter?.operator || null,
                  type: subFilter?.type || null,
                },
                id: generateId(),
                operator: subFilter?.operator || null,
                value: String(subFilter?.filter) || null,
                valueSource: "value",
              };
            }),
          },
        };
      });

      subtabs = [...subtabs, ...variationSubtabs];

      return {
        name: item.tokenName,
        subtabs,
      };
    });

    if (newData && newData.length > 0) {
      setMainTabs(newData);
    }
  }, [modifiedData?.id]);

  const updateDynamicContentMergeTags = () => {
    const dynamicContentMergeTags = transformMainTabsToMergeTags(mainTabs);
    const newMergeTags = {
      ...data?.mergeTags,
      dynamicContent: {
        name: "Dynamic Content",
        mergeTags: dynamicContentMergeTags,
      },
    };
    if (emailEditorRef?.current?.editor) {
      emailEditorRef.current.editor.setMergeTags(newMergeTags);
    }
  };

  useEffect(() => {
    if (shouldApply) {
      apply(applyBuilder);
      setShouldApply(false);
    }
  }, [modifiedData, shouldApply]);

  const onDesignLoad = (data) => {};

  useEffect(() => {
    onReady();
  }, []); // Trigger onReady on component mount

  const changeDynamicTab = (e) => {
    setPrimaryColorTab(e);
  };

  function transformData(mainTabs) {
    const transformedData = mainTabs?.map((tab) => {
      const defaultSubtab = tab?.subtabs?.find((subtab) => subtab.name === "Default");
      const content = defaultSubtab ? defaultSubtab?.inputValue : "";

      const filters = tab.subtabs
        .map((subtab, index) => {
          if (index < 2) return null;

          const subFilters = subtab?.selectValues?.rules?.map((rule) => {
            return {
              glue: subtab.selectValues.combinator,
              operator: rule.operator,
              filter: rule.value,
              display: null,
              field: rule.field.field,
              type: rule.field.type,
              object: rule.field.object,
            };
          });

          return {
            content: subtab.inputValue,
            filters: subFilters,
          };
        })
        .filter(Boolean);

      return {
        tokenName: tab.name,
        content: content,
        filters: filters,
      };
    });

    setModifiedData((prevState) => ({
      ...prevState,
      dynamicContent: transformedData,
    }));
  }

  const applyAndCloseTemplate = (params) => {
    emailEditorRef.current.editor.exportHtml((data) => {
      const { design, html } = data;

      // Ensure line breaks are properly encoded
      const encodedHtml = Buffer.from(html).toString("base64");
      const encodedJson = Buffer.from(JSON.stringify(design, undefined)).toString("base64");

      setModifiedData((prev) => ({
        ...prev,
        customHtml: encodedHtml,
        customJson: encodedJson,
      }));

      // Directly update the editor with the latest design
      emailEditorRef?.current?.editor.loadDesign(design);

      if (params === "applyBuilder") {
        setApplyBuilder("applyBuilder");
      }

      if (params === "savebuilder") {
        setShouldApply(true);
      }
      setShouldApply(true);
    });
  };
  const [editorLoaded, setEditorLoaded] = useState(false); // State to track if the editor has been loaded

  const onReady = () => {
    // Ensure that the editor is fully ready before proceeding
    const editorInstance = emailEditorRef?.current?.editor;

    if (editorInstance) {
      // Register the image upload callback (only if not registered already)
      if (!editorInstance.hasRegisteredImageCallback) {
        editorInstance.hasRegisteredImageCallback = true;
        editorInstance.registerCallback("image", (file, done) => {
          const data = new FormData();
          data.append("file", file.attachments[0]);

          fetch(`${api}/api/unlayer/image/upload`, {
            method: "POST",
            headers: {
              Accept: "application/json",
              Authorization: `JWT ${token}`, // Add JWT token here
            },
            body: data,
          })
            .then((response) => {
              if (response.status >= 200 && response.status < 300) {
                return response.json();
              } else {
                const error = new Error(`Failed to upload: ${response.statusText}`);
                error.response = response;
                throw error;
              }
            })
            .then((responseData) => {
              done({
                progress: 100,
                url: responseData?.fileUrl, // Adjust based on your API response
              });
            })
            .catch((error) => {
              console.error("Image upload failed:", error);
              done({
                progress: 0,
                url: null,
                error: "Upload failed. Please try again.",
              });
            });
        });
      }

      // If customJson exists and editor has not been loaded
      if (modifiedData.customJson && data && !editorLoaded) {
        try {
          emailEditorRef?.current?.editor.loadDesign(JSON.parse(modifiedData.customJson));
          setEditorLoaded(true); // Mark editor as loaded after the design is applied

          // Check if there are display conditions to load
          if (modifiedData.dynamicContent?.length > 0) {
            modifiedData.dynamicContent.forEach((item) => {
              if (item.filters?.length > 0) {
                setInitalDisplayContent(item.filters.map((filter) => filter.content));
                setApplyDCond(true); // Trigger the application of display conditions
              }
            });
          }
        } catch (error) {
          console.error("Error parsing JSON: ", error);
        }
      }

      // Always update merge tags
      updateDynamicContentMergeTags();
    }
  };

  useEffect(() => {
    if (!editorLoaded && modifiedData.customJson && data) {
      onReady();
    }
  }, [modifiedData.customJson, data]);

  useEffect(() => {
    setEditorLoaded(false); // Reset editorLoaded flag when data changes
  }, [modifiedData.customJson, data]);

  const previewButton = [
    {
      name: "Preview",
      title: t("caymland.core.preview") + " " + "E-Mail",
      icon: <VisibilityIcon size={15} />,
      onClick: () => {
        window.open(`/email/preview/${modifiedData?.id}`, "_blank");
      },
    },
  ];
  const themesButton = [
    {
      name: "themes",
      title: t("caymland.core.filter.themes"),
      icon: <StyleOutlinedIcon size={15} />,
      onClick: () => setIsOpenModal(!isOpenModal),
    },
  ];

  const { isAdmin } = useSelector((state) => state?.auth?.user?.role || {});

  return (
    <>
      <DisplayConditionModal
        segmentOptions={segmentOptions}
        setOpenModal={setOpenModal}
        openModal={openModal}
        rightColumnValue={rightColumnValue}
        setRightColumnValue={setRightColumnValue}
        emailEditorRef={emailEditorRef}
        setApplyDCond={setApplyDCond}
      />
      <Nav className="nav-primary w-full nav-builder" style={{ marginTop: "10px" }} tabs>
        <NavItem>
          <NavLink
            href="#"
            className={primarycolorTab === 1 ? "active" : ""}
            onClick={() => changeDynamicTab(1)}
            style={{
              color: "#313131",
              textTransform: "capitalize",
            }}
          >
            {t("caymland.email.header.new")}
          </NavLink>
        </NavItem>
        <NavItem>
          <NavLink
            href="#"
            className={primarycolorTab === 2 ? "active" : ""}
            onClick={() => changeDynamicTab(2)}
            style={{
              color: "#313131",
              textTransform: "capitalize",
            }}
          >
            {t("caymland.core.dynamicContent")}
          </NavLink>
        </NavItem>
        {isAdmin && (
          <>
            {" "}
            <NavItem>
              <NavLink
                href="#"
                className={primarycolorTab === 3 ? "active" : ""}
                onClick={() => changeDynamicTab(3)}
                style={{
                  color: "#313131",
                  textTransform: "capitalize",
                }}
              >
                {t("caymland.email.custom.json")}
              </NavLink>
            </NavItem>
            <NavItem>
              <NavLink
                href="#"
                className={primarycolorTab === 4 ? "active" : ""}
                onClick={() => changeDynamicTab(4)}
                style={{
                  color: "#313131",
                  textTransform: "capitalize",
                }}
              >
                {t("caymland.email.custom.html")}
              </NavLink>
            </NavItem>{" "}
          </>
        )}

        <div style={{ display: "flex", marginLeft: "auto", padding: "0 10px 10px 0px", gap: "5px" }}>
          {/* {id && (
            <div className="tw-min-w-[200px]">
              <Select
                options={retrieveVersions}
                value={version?.value ?? null}
                isClearable={false}
                margin
                onChange={(e) => handleVersion(e)}
              />
            </div>
          )} */}

          {Object.keys(themes).length > 0 && <Button height={30} buttons={themesButton} />}
          {isEdit && <Button height={30} buttons={previewButton} />}
        </div>
      </Nav>
      <TabContent activeTab={primarycolorTab} style={{ height: "calc(100% - 50px)" }}>
        <TabPane tabId={1} style={{ flex: "1", height: "100%" }}>
          {data && <EmailEditor ref={emailEditorRef} onReady={onReady} options={data} minHeight="100%" />}
        </TabPane>
        <TabPane tabId={2} style={{ flex: "1" }}>
          <DynamicTabsContainer mainTabs={mainTabs} setMainTabs={setMainTabs} />
        </TabPane>
        <TabPane tabId={3} style={{ flex: "1", height: "84vh" }}>
          <CustomJson modifiedData={modifiedData} setModifiedData={setModifiedData} />
        </TabPane>
        <TabPane tabId={4} style={{ flex: "1", height: "84vh" }}>
          <CustomHtml modifiedData={modifiedData} setModifiedData={setModifiedData} />
        </TabPane>
      </TabContent>
    </>
  );
});

export default Builder;
