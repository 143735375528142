import React, { useEffect, useMemo, useState } from "react";
import Modal from "react-bootstrap/Modal";
import Tooltip from "@mui/material/Tooltip";
import { IconButton } from "@mui/material";
import { Add } from "@mui/icons-material";
import { Checkbox, DatePicker, Select, Text, TextArea } from "../../../../reusableComponents/Inputs";
import CustomCreatableSelectInput from "../../../../reusableComponents/Inputs/InputSelect";
import { ImCancelCircle } from "react-icons/im";
import { TbDeviceFloppy } from "react-icons/tb";
import { Nav, NavItem, NavLink, TabContent, TabPane } from "reactstrap";

import Button from "../../../../reusableComponents/Buttons/Button";
import {
  useAddDocumentNoteMutation,
  useAddNoteActionTagsMutation,
  useAddNoteTagsMutation,
  useDeleteDocumentNoteMutation,
  useDownloadNoteDocumentMutation,
  useGetNoteActionTagsQuery,
  useGetNoteTagsQuery,
  useNoteByIdQuery,
} from "../../../../../redux/api/contactsApi";
import { t } from "i18next";
import Dropzone from "react-dropzone-uploader";
import CustomTable from "../../CustomTable/CustomTable";
import { useGetUserSelectOptionsQuery, useGetUsersOptionsQuery } from "../../../../../redux/api/usersApi";
import moment from "moment";
import dayjs from "dayjs";
import { useSelector } from "react-redux";
import { ActionButtons } from "../../../../reusableComponents/tableComponents/ActionButtons";
import { IoIosWarning } from "react-icons/io";

const CustomModal = ({ showModal, setShowModal, addNote, id, refetch, add, updateNote, setNoteTypes }) => {
  const [formData, setFormData] = useState({
    text: "",
    type: "general", // Default type
    dateTime: new Date(),
    noteAction: {
      dateTimeOfNotification: new Date(),
      isPublished: false,
    },
    lead: id,
  });
  const [errors, setErrors] = useState({});

  const { isAdmin, name } = useSelector((state) => state?.auth?.user?.role || {});

  const [documentsData, setDocumentsData] = useState([]);
  const [tempFileName, setTempFileName] = useState();
  const [addDocumentNote] = useAddDocumentNoteMutation();
  const [addNoteTag] = useAddNoteTagsMutation();
  const [addNoteActionTags] = useAddNoteActionTagsMutation();
  const [downloadFile] = useDownloadNoteDocumentMutation();
  const user = useSelector((state) => state.auth.user);

  const { data: ownerOptions } = useGetUserSelectOptionsQuery();
  const { data: noteActionTagsOptions } = useGetNoteActionTagsQuery();
  const { data: noteTagsOptions } = useGetNoteTagsQuery();

  const [query, setQuery] = useState({
    pageIndex: 0,
    pageSize: sessionStorage.getItem("pageSize") ? parseInt(sessionStorage.getItem("pageSize"), 10) : 30,
    orderByDir: "DESC",
    orderBy: "uf.dateAdded",
  });
  const { data: noteById, refetch: refetchDocument } = useNoteByIdQuery({ id: showModal.id }, { skip: !showModal.id });
  const [deleteDocumentNote] = useDeleteDocumentNoteMutation();
  const [activeTab, setActiveTab] = useState("1");

  useEffect(() => {
    const ensureWrappedInParagraph = (text) => {
      if (!text) return "<p></p>"; // Default to an empty paragraph if text is null or undefined
      const formattedText = text.trim().replace(/\n/g, "<br>"); // Replace all newline characters with <br>
      // Check if text is already wrapped in <p>...</p>
      if (formattedText.startsWith("<p>") && formattedText.endsWith("</p>")) {
        return formattedText;
      }
      // Wrap the text in <p>...</p>
      return `<p>${formattedText}</p>`;
    };

    if (noteById?.note && showModal.id) {
      setFormData({
        ...formData,
        subject: noteById?.note?.subject || "",
        text: ensureWrappedInParagraph(noteById.note?.text || ""),
        type: noteById?.note?.type || "general",
        dateTime: noteById?.note?.dateTime ? new Date(noteById.note.dateTime) : new Date(),
        tags: noteById?.note?.noteTags
          ? noteById?.note?.noteTags.map((tag) => ({ value: tag.id, label: tag.tag }))
          : [],
        noteAction: {
          isPublished: noteById?.note?.noteAction?.isPublished ?? false,
          user: noteById?.note?.noteAction?.user?.id || "",
          dateTimeOfNotification: noteById?.note?.noteAction?.dateTimeOfNotification
            ? new Date(noteById?.note?.noteAction?.dateTimeOfNotification)
            : null,
          tags: noteById?.note?.noteAction?.noteActionTags
            ? noteById?.note?.noteAction?.noteActionTags.map((tag) => ({ value: tag.id, label: tag.tag }))
            : [],
        },
        // documents: noteById?.documents?.map((item) => item.id) || [],
      });
      setDocumentsData(noteById?.note?.documents);
    }
  }, [noteById, showModal.id]);

  const handleTextChange = (e) => {
    setFormData({ ...formData, text: e.target.value });
  };

  const handleTypeChange = (e) => {
    setFormData({ ...formData, type: e.value });
  };

  const toggleTab = (tab) => {
    if (activeTab !== tab) setActiveTab(tab);
  };

  const options = [
    { label: t("caymland.lead.note.type.general"), value: "general" },
    { label: t("caymland.lead.note.type.call"), value: "call" },
    { label: t("caymland.lead.note.type.email"), value: "email" },
    { label: t("caymland.lead.note.type.meeting"), value: "meeting" },
  ];

  const handleClose = () => {
    setFormData({
      text: "",
      type: "general",
      dateTime: new Date(),
      lead: id,
    });
    setShowModal({ isOpen: false, id: null });
    setErrors({});

    setDocumentsData([]);
  };

  const formatDateTime = (date, format = "YYYY-MM-DD HH:mm") => {
    return date ? moment(date).format(format) : null;
  };

  const extractIds = (data, idKey = "id", fallbackKey = "fileId") => {
    return (data || [])
      .filter((item) => item[idKey] || item[fallbackKey])
      .map((item) => (item[idKey] || item[fallbackKey]).toString());
  };

  const extractLabels = (tags) => {
    return (tags || []).map((tag) => tag.label);
  };

  const buttons = [
    {
      name: "close",
      title: t("caymland.core.form.close"),
      icon: <ImCancelCircle size={15} />,
      onClick: handleClose,
    },
    {
      name: "save",
      title: t("caymland.core.form.save"),
      icon: <TbDeviceFloppy size={15} />,
      onClick: () => {
        let newErrors = {};
        if (!formData.subject) {
          newErrors.subject = t("This field is required");
        }
        if (!formData?.noteAction?.user) {
          newErrors.user = t("This field is required");
        }

        if (!formData.text) {
          newErrors.text = t("This field is required");
        }

        if (Object.keys(newErrors).length > 0) {
          setErrors(newErrors);
          return; // Stop the save operation
        }

        let updatedFormData = {
          ...formData,
          lead: id,
          dateTime: moment(formData.dateTime).toISOString(),
          noteAction: {
            ...formData.noteAction,
            dateTimeOfNotification: formatDateTime(formData.noteAction?.dateTimeOfNotification),
            tags: extractLabels(formData.noteAction?.tags),
            isPublished: formData?.noteAction?.isPublished ?? false,
          },
          tags: extractLabels(formData.tags),
          fileIds: extractIds(documentsData),
        };

        if (showModal.type === "edit") {
          updateNote({
            id: showModal.id,
            ...updatedFormData,
          });
        } else {
          addNote({
            ...updatedFormData,
          });
        }

        setTimeout(refetch, 1000);
        setNoteTypes([]);
        setFormData({
          text: "",
          type: "general",
          dateTime: new Date(),
          lead: id,
        });
        setShowModal({ isOpen: false });
        setDocumentsData([]);
      },
    },
  ];

  const formatSize = (sizeInBytes) => {
    if (!sizeInBytes) return "0 Bytes"; // Handle undefined or null values

    const units = ["Bytes", "KB", "MB", "GB", "TB"];
    let index = 0;

    let size = sizeInBytes;
    while (size >= 1024 && index < units.length - 1) {
      size /= 1024;
      index++;
    }

    return `${size.toFixed(2)} ${units[index]}`;
  };

  const columns = useMemo(() => [
    {
      accessorKey: "fileName",
      accessorFn: (row) => {
        const displayName =
          row?.fileOriginalName?.length > 30
            ? row?.fileOriginalName.substring(0, 30) + "..."
            : row?.fileOriginalName || row?.fileId;

        if (!row.fileOriginalName) {
          // If fileOriginalName is missing, show fileId as plain text
          return <span>{tempFileName}</span>;
        }

        const handleFileClick = async (e) => {
          e.preventDefault();
          try {
            const response = await downloadFile(row.fileName).unwrap();
            const blob = new Blob([response], { type: response.type });
            const url = window.URL.createObjectURL(blob);
            const a = document.createElement("a");
            a.style.display = "none";
            a.href = url;
            a.download = row.fileOriginalName;
            document.body.appendChild(a);
            a.click();
            document.body.removeChild(a);
            window.URL.revokeObjectURL(url);
          } catch (error) {
            console.error("Failed to download file", error);
          }
        };

        return (
          <a href="#" onClick={handleFileClick}>
            {displayName}
          </a>
        );
      },
      header: t("caymland.core.name"),
      enableColumnActions: false,
    },
    {
      accessorKey: "size",
      accessorFn: (row) => formatSize(row?.size), // Convert size to human-readable format
      header: t("caymland.focus.form.bar.size"),
      enableColumnActions: false,
    },
    {
      accessorKey: "added",
      accessorFn: (row) => (row.owner ? row?.owner?.firstName + " " + row?.owner?.lastName : row.user.name), // Convert size to human-readable format
      header: t("caymland.lead.segments.contacts.added"),
      enableColumnActions: false,
    },
  ]);
  const handleCreateOption = async (inputValue) => {
    const newTag = { label: inputValue, value: inputValue };

    // Update tags in the formData state without altering existing ones
    setFormData((prevFormData) => ({
      ...prevFormData,
      tags: [...(prevFormData.tags || []), newTag], // Append new tag while preserving existing tags
    }));

    // Optionally make the API call to save the new tag
    try {
      await addNoteTag({ tag: inputValue });
    } catch (error) {
      console.error("Error adding new tag:", error);
    }
  };
  const handleCreateOptionActionTags = async (inputValue) => {
    const newTag = { label: inputValue, value: inputValue };

    // Update tags inside noteAction in the formData state
    setFormData((prevFormData) => ({
      ...prevFormData,
      noteAction: {
        ...prevFormData.noteAction, // Preserve other properties of noteAction
        tags: [...(prevFormData?.noteAction?.tags || []), newTag], // Append new tag while preserving existing tags
      },
    }));

    // Optionally make the API call to save the new tag
    try {
      await addNoteActionTags({ tag: inputValue });
    } catch (error) {
      console.error("Error adding new tag:", error);
    }
  };
  const getUploadParams = async ({ file }) => {
    try {
      const formData = new FormData();
      formData.append("file", file); // Ensure file is added as the "file" key
      setTempFileName(file?.name);
      // Use the RTK Query mutation to upload the file
      const response = await addDocumentNote(formData).unwrap();
      setDocumentsData((prevDocuments) => [...prevDocuments, response]);
      return { meta: { uploadUrl: response } }; // Handle the response as needed
    } catch (error) {
      console.error("File upload failed:", error);
      return { meta: { uploadUrl: null } };
    }
  };
  const safeNewDate = (dateString) => {
    if (dateString) {
      const parsedDate = dayjs(dateString);
      if (parsedDate.isValid()) {
        return parsedDate.toDate();
      }
    }
    return dayjs().toDate();
  };
  const actionButtons = ActionButtons({
    link: "notes",
    clone: false,
    edit: false,
    deleteItem: deleteDocumentNote,
    refetch: refetchDocument,
  });

  console.log(formData.noteAction?.user);
  console.log(user.id);
  return (
    <>
      <Modal show={showModal.isOpen} onHide={handleClose} enforceFocus={false}>
        <Modal.Header closeButton>
          <Modal.Title>
            {showModal.type === "edit" ? t("caymland.lead.note.header.edit") : t("caymland.lead.add.note")}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body className="w-full">
          <Nav tabs className="tw-mb-5">
            <NavItem>
              <NavLink
                className={activeTab == "1" ? "active-bg" : ""}
                onClick={() => toggleTab("1")}
                style={{ display: "flex", gap: "8px", alignItems: "center" }}
              >
                {t("caymland.lottery.tab.general")}
                {(errors.subject || errors.text) && activeTab !== "1" && <IoIosWarning color="red" />}
              </NavLink>
            </NavItem>
            <NavItem>
              <NavLink
                className={activeTab == "2" ? "active-bg" : ""}
                onClick={() => toggleTab("2")}
                style={{ display: "flex", gap: "8px", alignItems: "center" }}
              >
                {t("caymland.campaign.event.action.header")}
                {errors.user && activeTab !== "2" && <IoIosWarning color="red" />}
              </NavLink>
            </NavItem>
            <NavItem>
              <NavLink className={activeTab == "3" ? "active-bg" : ""} onClick={() => toggleTab("3")}>
                Dokumente
              </NavLink>
            </NavItem>
          </Nav>
          <TabContent activeTab={activeTab} className="w-full">
            <TabPane tabId="1" className="w-full">
              <div className="tw-mt-5">
                <Text
                  name="subject"
                  label={t("caymland.email.subject")}
                  value={formData?.subject ?? ""}
                  required={true}
                  errors={errors?.subject} // Pass error message to the component
                  onChange={(e) => {
                    setFormData((prev) => ({
                      ...prev,
                      subject: e.target.value,
                    }));
                    setErrors((prevErrors) => ({
                      ...prevErrors,
                      subject: null, // Clear the user error
                    }));
                  }}
                />
                <TextArea
                  label={"Text"}
                  showSmallEditor={false}
                  value={formData?.text ?? ""}
                  required={true}
                  errors={errors?.text}
                  onChange={(data) => {
                    setFormData((prev) => ({
                      ...prev,
                      text: data,
                    }));
                    setErrors((prevErrors) => ({
                      ...prevErrors,
                      text: null, // Clear the user error
                    }));
                  }}
                />

                <div className="d-flex items-center justify-between gap-10">
                  <Select
                    name="type"
                    label={t("caymland.core.type")}
                    value={formData?.type}
                    onChange={handleTypeChange}
                    options={options}
                  />
                  <DatePicker
                    name="dateTime"
                    showTimeSelect
                    label={t("caymland.core.select.datetime")}
                    value={formData?.dateTime ? new Date(formData?.dateTime) : null}
                    onChange={(e) => setFormData({ ...formData, dateTime: e })}
                  />
                </div>
                {name == "Company Admin" || isAdmin ? (
                  <CustomCreatableSelectInput
                    name="tags"
                    label={t("caymland.campaign.lead.tags")}
                    value={formData?.tags || []}
                    isMulti
                    onChange={(e) => setFormData({ ...formData, tags: e })}
                    options={noteTagsOptions}
                    onCreateOption={handleCreateOption}
                  />
                ) : (
                  <Select
                    name="tags"
                    label={t("caymland.campaign.lead.tags")}
                    value={formData?.tags || []}
                    isMulti
                    onChange={(e) => setFormData({ ...formData, tags: e })}
                    options={noteTagsOptions}
                    onCreateOption={handleCreateOption}
                  />
                )}
              </div>
            </TabPane>
            <TabPane tabId="2">
              <div className="tw-mt-5">
                <Select
                  name="user"
                  label={t("caymland.lead.note.action.form.user")}
                  value={
                    ownerOptions?.find((option) =>
                      formData.noteAction?.user ? option.value == formData.noteAction?.user : option.value == user.id
                    ) || null
                  }
                  required={true}
                  errors={errors?.user} // Pass error message to the component
                  onChange={(selected) => {
                    setFormData((prev) => ({
                      ...prev,
                      noteAction: { ...prev.noteAction, user: selected?.value },
                    }));
                    setErrors((prevErrors) => ({
                      ...prevErrors,
                      user: null, // Clear the user error
                    }));
                  }}
                  options={ownerOptions}
                />
                <DatePicker
                  name="dateTimeOfNotification"
                  label={t("caymland.lead.note.action.form.date")}
                  showTimeSelect
                  minDate={new Date()} // Restrict selection to today or later
                  value={formData?.noteAction?.dateTimeOfNotification || null}
                  onChange={(date) =>
                    setFormData((prev) => ({
                      ...prev,
                      noteAction: { ...prev.noteAction, dateTimeOfNotification: date },
                    }))
                  }
                />
                {name == "Company Admin" || isAdmin ? (
                  <CustomCreatableSelectInput
                    name="tags"
                    label={t("Nächste Aktion")}
                    isMulti
                    value={formData?.noteAction?.tags || []}
                    onChange={(selected) =>
                      setFormData((prev) => ({
                        ...prev,
                        noteAction: { ...prev.noteAction, tags: selected },
                      }))
                    }
                    options={noteActionTagsOptions}
                    onCreateOption={handleCreateOptionActionTags}
                  />
                ) : (
                  <Select
                    name="tags"
                    label={t("Nächste Aktion")}
                    isMulti
                    value={formData?.noteAction?.tags || []}
                    onChange={(selected) =>
                      setFormData((prev) => ({
                        ...prev,
                        noteAction: { ...prev.noteAction, tags: selected },
                      }))
                    }
                    options={noteActionTagsOptions}
                  />
                )}

                <Checkbox
                  name="isPublished"
                  label={t("E-Mail senden")}
                  value={formData?.noteAction?.isPublished ?? false} // Use `checked` instead of `value` for checkboxes
                  onChange={(e) =>
                    setFormData((prev) => ({
                      ...prev,
                      noteAction: { ...prev.noteAction, isPublished: e.target.checked }, // Update the `isPublished` value
                    }))
                  }
                />
              </div>
            </TabPane>
            <TabPane tabId="3">
              <div className="tw-mt-5">
                {" "}
                <Dropzone
                  getUploadParams={getUploadParams}
                  onChangeStatus={({ meta, file }, status) => {
                    if (status === "done") {
                      handleUpload(file);
                    } else if (status === "removed") {
                    }
                  }}
                  accept="*" // Update accepted file types as per requirements
                  inputContent={t("caymland.asset.drop.file.here")}
                  styles={{
                    dropzoneActive: { borderColor: "green" },
                  }}
                />
                <CustomTable
                  data={documentsData}
                  columns={columns}
                  query={query}
                  setQuery={setQuery}
                  actionButtons={actionButtons}
                  smallHeight
                  linkNavigate={null}
                  actions
                />
              </div>
            </TabPane>
          </TabContent>
        </Modal.Body>
        <Modal.Footer>
          <Button buttons={buttons} />
        </Modal.Footer>
      </Modal>
      <Tooltip arrow title={t("caymland.lead.add.note")} onClick={() => setShowModal({ isOpen: true })}>
        <IconButton>
          <Add />
        </IconButton>
      </Tooltip>
    </>
  );
};

export default CustomModal;
