import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { customFetchBaseQuery } from "../utils/customFetchBaseQuery";
import { customTransformResponse } from "../utils/customTransformResponse";
import qs from "qs";

export const emailsApi = createApi({
  reducerPath: "emailsApi",
  baseQuery: customFetchBaseQuery,
  tagTypes: ["Emails"],
  keepUnusedDataFor: 30,
  endpoints: (build) => ({
    getEmails: build.query({
      query: (q) =>
        `/api/emails?${qs.stringify({
          ...q,
          ...{ start: q.pageIndex * q.pageSize, limit: q.pageSize },
        })}`,
      keepUnusedDataFor: false,
      providesTags: ["Emails"],
      transformResponse: customTransformResponse,
      forceRefetch({ currentArg, previousArg }) {
        return currentArg !== previousArg;
      },
    }),
    getEmailsOptions: build.query({
      query: (q) => `/api/emails/select`,
      keepUnusedDataFor: false,
      providesTags: ["Emails"],
      forceRefetch({ currentArg, previousArg }) {
        return currentArg !== previousArg;
      },
    }),
    getEmailsTemplates: build.query({
      query: (q) => `/api/emails`,
      keepUnusedDataFor: false,
      providesTags: ["Emails"],
      transformResponse: customTransformResponse,
      forceRefetch({ currentArg, previousArg }) {
        return currentArg !== previousArg;
      },
    }),
    addEmail: build.mutation({
      query: (body) => ({
        url: `api/emails/new`,
        method: "POST",
        body,
      }),
    }),
    updateEmails: build.mutation({
      query: ({ id, ...patch }) => ({
        url: `/api/emails/${id}/edit`,
        method: "PATCH",
        body: patch,
      }),
      invalidatesTags: ["Emails"],
    }),
    deleteEmail: build.mutation({
      query: (id) => ({
        url: `/api/emails/${id}/delete`,
        method: "DELETE",
      }),
      invalidatesTags: ["Emails"],
    }),
    //togglePublicPreview
    togglePublicPreview: build.mutation({
      query: (id) => ({
        url: `/api/emails/${id}/publicPreview`,
        method: "PATCH",
      }),
    }),
    //sendExample
    sendExample: build.mutation({
      query: ({ id, body }) => ({
        url: `api/emails/${id}/sendExample`,
        method: "POST",
        body,
      }),
    }),
    emailTesting: build.query({
      query: ({ id, contactId }) => ({
        url: `/api/emails/testing`,
        method: "POST",
        body: {
          contactId: contactId,
          emailId: id,
        },
      }),
      forceRefetch({ currentArg, previousArg }) {
        return currentArg !== previousArg;
      },
      invalidatesTags: [{ type: "Emails" }],
      validateStatus: (response, result) => response.status === 200 && !result.isError,
      responseHandler: (response) => response.text(),
    }),
    //GET THEMES TEMPLATES AND OTHER
    getAddEditEmails: build.query({
      queryFn: async ({ id, query }, { signal, dispatch, getState }, extraOptions, baseQuery) => {
        console.log(id);
        if (id) {
          let emailById = await baseQuery({ url: `/api/emails/${id}` });
          let emailsThemes = await baseQuery({
            url: `/api/themes/templates/email`,
          });
          let emailTemplate = await baseQuery({
            url: `/api/emails?where[0][expr]=eq&where[0][col]=isTemplate&where[0][val]=1`,
          });
          let emailPages = await baseQuery({
            url: `/api/pages?where[0][expr]=eq&where[0][col]=isPreferenceCenter&where[0][val]=1`,
          });
          let getExtras = null;
          if (query) {
            getExtras = await baseQuery({
              url: `api/emails/${id}/extras?${query}`,
            });
          }

          const email = emailById?.data;
          const themes = emailsThemes?.data;
          const templates = emailTemplate?.data;
          const pages = emailPages?.data;
          const extras = getExtras?.data;

          const data = {
            email,
            themes,
            templates,
            pages,
            extras,
          };

          return { data: data };
        } else {
          let emailsThemes = await baseQuery({
            url: `/api/themes/templates/email`,
          });
          let emailTemplate = await baseQuery({
            url: `/api/emails?where[0][expr]=eq&where[0][col]=isTemplate&where[0][val]=1`,
          });
          let emailPages = await baseQuery({
            url: `/api/pages?where[0][expr]=eq&where[0][col]=isPreferenceCenter&where[0][val]=1`,
          });

          const themes = emailsThemes?.data;
          const templates = emailTemplate?.data;
          const pages = emailPages?.data;

          const data = {
            themes,
            templates,
            pages,
          };

          return { data: data };
        }
      },
      providesTags: (result, error, id) => [{ type: "Emails", id }],
    }),
    postAdvancedReports: build.mutation({
      query: ({ id, body }) => ({
        url: `/api/emailsreport/report/${id}`,
        method: "POST",
        body,
      }),
    }),
    downloadReport: build.mutation({
      query: ({ id }) => ({
        url: `api/emailsreport/download/${id}`,
        method: "GET",
        responseHandler: (response) => response.blob(),
      }),
    }),
    getEmailEditorOption: build.query({
      query: () => ({
        url: `/api/emails/editor/options`,
      }),
      keepUnusedDataFor: 60,
    }),
    //FOLDERS
    //GET FOLDERS
    getFolderEmails: build.query({
      query: (q) => `/api/folders?limit=100&where[0][col]=source&where[0][expr]=eq&where[0][val]=emails`,
      transformResponse: customTransformResponse,
      serializeQueryArgs: ({ endpointName }) => {
        return endpointName;
      },

      forceRefetch({ currentArg, previousArg }, arg) {
        return currentArg !== previousArg;
      },
    }),
    //ADD FOLDER
    addFolderEmails: build.mutation({
      query: (body) => ({
        url: `api/folders/new`,
        method: "POST",
        body,
      }),
    }),
    //EDIT FOLDER
    updateFolderEmails: build.mutation({
      query: ({ id, ...patch }) => ({
        url: `api/folders/${id}/edit`,
        method: "PATCH",
        body: patch,
      }),
      invalidatesTags: (result, error, { id }) => [{ type: "Emails", id }],
    }),
    //DELETE FOLDER
    deleteFolderEmails: build.mutation({
      query: (id) => ({
        url: `/api/folders/${id}/delete`,
        method: "DELETE",
      }),

      invalidatesTags: (result, error, id) => [{ type: "Emails", id }],
    }),
    //MOVE EMAIL TO FOLDER
    updateEmailsToFolders: build.mutation({
      query: ({ id, itemId, ...patch }) => ({
        url: `api/folders/${id}/emails/${itemId}`,
        method: "PATCH",
      }),
    }),
    //REMOVE EMAIL TO FOLDER
    removeEmailsToFolders: build.mutation({
      query: ({ id, itemId, ...patch }) => ({
        url: `api/folders/${id}/emails/${itemId}`,
        method: "DELETE",
      }),
    }),
    //Get Segments for Select
    getSelectSegments: build.query({
      query: (type) => `/api/segments?where[0][expr]=eq&where[0][col]=isPublished&where[0][val]=1`,
      providesTags: ["Emails"],
    }),
    getEmailPreview: build.query({
      query: (id) => ({
        url: `/api/emails/${id}/publicPreview`,
        method: "PATCH",
      }),
      providesTags: ["Emails"],
    }),
    getEmailPreviewToken: build.query({
      query: (id) => ({
        url: `/api/emails/${id}/preview`,
        method: "GET",
      }),
      providesTags: ["Emails"],
    }),
    getEmailPreviewHash: build.query({
      query: (id) => ({
        url: `/api/emails/preview/${id}`,
        method: "GET",
      }),
      providesTags: ["Emails"],
    }),
    getEmail: build.query({
      query: (id) => ({
        url: `/api/emails/${id}`,
      }),
      providesTags: (result, error, id) => [{ type: "Emails", id }],
      keepUnusedDataFor: false,
    }),
    getEmailVersions: build.query({
      query: ({ id, q }) => ({
        url: `/api/emails/${id}/versions?${qs.stringify(q)}`,
      }),
      providesTags: (result, error, id) => [{ type: "Emails", id }],
      keepUnusedDataFor: false,
    }),
    sendSegmentEmail: build.mutation({
      query: ({ id, ...body }) => ({
        url: `api/runner/email/${id}/send`,
        method: "POST",
      }),
    }),
    stopSegmentEmail: build.mutation({
      query: ({ id, ...body }) => ({
        url: `api/runner/email/${id}/stop`,
        method: "POST",
      }),
    }),
    sendEmailToContact: build.mutation({
      query: ({ id, contactId }) => ({
        url: `api/emails/${id}/contact/${contactId}/send`,
        method: "POST",
      }),
    }),
  }),
});

export const {
  useGetEmailQuery,
  useGetEmailEditorOptionQuery,
  useAddEmailMutation,
  useGetEmailsQuery,
  useGetEmailsOptionsQuery,
  useUpdateEmailsMutation,
  useDeleteEmailMutation,
  useSendEmailToContactMutation,
  useGetEmailVersionsQuery,
  //GET SELECT SEGMENTS
  useGetSelectSegmentsQuery,
  //FOLDERS
  useGetFolderEmailsQuery,
  useUpdateFolderEmailsMutation,
  useDeleteFolderEmailsMutation,
  useAddFolderEmailsMutation,
  useUpdateEmailsToFoldersMutation,
  useRemoveEmailsToFoldersMutation,
  useGetAddEditEmailsQuery,
  useSendExampleMutation,
  useTogglePublicPreviewMutation,
  usePostAdvancedReportsMutation,
  useDownloadReportMutation,
  //PREVIEW
  useGetEmailPreviewQuery,
  useGetEmailsTemplatesQuery,
  useEmailTestingQuery,
  useGetEmailPreviewHashQuery,
  useGetEmailPreviewTokenQuery,
  //SEND AND STOP SEGMENT EMAIL
  useSendSegmentEmailMutation,
  useStopSegmentEmailMutation,
} = emailsApi;
