import React, { useMemo, useCallback, useState } from "react";
import QueryBuilder from "react-querybuilder";
import { FiltersFieldSelector } from "./FiltersFieldSelector";
import { IncomingTypeReusible } from "./IncomingTypeReusible";
import ValueReusible from "./ValueReusible";
import { useLocation } from "react-router-dom";
import "react-querybuilder/dist/query-builder.scss";
import Button from "../../reusableComponents/Buttons/Button";
import ClearOutlinedIcon from "@mui/icons-material/ClearOutlined";
import Select from "react-select";
import { QueryBuilderDnD } from "@react-querybuilder/dnd";
import * as ReactDnD from "react-dnd";
import * as ReactDndHtml5Backend from "react-dnd-html5-backend";
import { useTranslation } from "react-i18next";
import { SlideAlert } from "../Alerts";
import { useSelector } from "react-redux";

const DEFAULT_LOCALE = "de";

const QueryFiltersReusible = ({
  query,
  setQuery,
  loading,
  fields,
  campaignData,
  segmentData,
  emailData,
  tagOptions,
  stageOptions,
  deviceBrands,
  deviceOSes,
  deviceTypes,
  globalCategory,
  groupButton = true,
}) => {
  
  const locale = useSelector((state) => state?.auth?.user?.locale || DEFAULT_LOCALE);
  const { t } = useTranslation();
  const location = useLocation();
  const [showAlert, setShowAlert] = useState(false);
  const CustomCombinatorSelector = (props) => {
    const { value, handleOnChange, disabled } = props;
    const options = [
      { name: "and", label: locale === "de" ? "UND" : "AND" },
      { name: "or", label: locale === "de" ? "ODER" : "OR" },
    ];

    const combinatorOptions = useMemo(
      () =>
        options.map((option) => ({
          label: option.label,
          value: option.name,
        })),
      [options]
    );

    const currentValue = useMemo(
      () => combinatorOptions.find((option) => option.value === value),
      [combinatorOptions, value]
    );

    const handleChange = useCallback(
      (selectedOption) => {
        if (handleOnChange && typeof handleOnChange === "function") {
          handleOnChange(selectedOption.value);
        }
      },
      [handleOnChange]
    );

    return (
      <div style={{ width: "30%" }}>
        <Select
          name="combinator"
          options={combinatorOptions}
          isClearable={false}
          value={currentValue}
          onChange={handleChange}
          isDisabled={disabled}
          theme={selectTheme}
          styles={selectStyles}
        />
      </div>
    );
  };

  const selectTheme = (theme) => ({
    ...theme,
    borderRadius: 4,
    colors: {
      ...theme.colors,
      primary25: "#135f95",
      primary: "#135f95",
    },
  });

  const selectStyles = {
    control: (base) => ({
      ...base,
      fontSize: "15px",
      transition: "transform 0.3s ease, box-shadow 0.3s ease",
      "&:hover": {
        transform: "scale(1.003)",
        boxShadow: "0 6px 20px rgba(0, 0, 0, 0.1)",
        borderColor: "#5A5A5A",
      },
    }),
    groupHeading: (provided) => ({
      ...provided,
      fontWeight: "bold",
      color: "#000",
      backgroundColor: "white",
    }),
    group: (provided) => ({
      ...provided,
      backgroundColor: "white",
      padding: "10px 2px",
    }),
    option: (provided, state) => ({
      ...provided,
      backgroundColor: state.isSelected ? "#347AA6" : "white",
      color: state.isSelected ? "white" : "#333",
      padding: "10px 2px",
      fontSize: "15px",
      transition: "background-color 0.2s ease",
      paddingLeft: "20px",
      "&:active": {
        color: "white",
        backgroundColor: "#135f95",
      },
      "&:hover": {
        color: "white",
        backgroundColor: "#135f95",
      },
      "&:focus": {
        color: "white",
      },
    }),
  };

  const CustomActionButton = (props) => {
    const { actionType, handleOnClick, level } = props;

    const isRemoveButton = actionType === "remove";
    const buttonConfig = isRemoveButton
      ? {
          name: "Remove",
          icon: <ClearOutlinedIcon />,
          onClick: (e) => handleOnClick(e),
        }
      : {
          name: props.label,
          title:
            props.label === "+Rule" ? "+" + t("caymland.mailbox.rules.index") : "+" + t("caymland.lead.field.group"),
          onClick: (e) => handleOnClick(e),
        };

    return <Button buttons={[buttonConfig]} />;
  };

  const germanTranslations = {
    // Add all required translations here
    fields: "Felder",
    operators: "Operatoren",
    value: "Wert",
    removeRule: "Regel entfernen",
    removeGroup: "Gruppe entfernen",
    addRule: "Regel hinzufügen",
    addGroup: "Gruppe hinzufügen",
    // Continue with all other keys you need to translate
  };

  return (
    <>
      <QueryBuilderDnD dnd={{ ...ReactDnD, ...ReactDndHtml5Backend }}>
        <QueryBuilder
          fields={fields}
          query={query}
          translations={germanTranslations}
          controlClassnames={{ queryBuilder: "queryBuilder-branches" }}
          onQueryChange={(q) => {
            setQuery(q);
          }}
          controlElements={{
            valueEditor: (props) => {
              return (
                <ValueReusible
                  {...props}
                  isDisabled={loading}
                  loading={loading}
                  fields={fields}
                  selectStyles={selectStyles}
                  selectTheme={selectTheme}
                  campaignData={campaignData}
                  segmentData={segmentData}
                  emailData={emailData}
                  tagOptions={tagOptions}
                  stageOptions={stageOptions}
                  deviceBrands={deviceBrands}
                  deviceOSes={deviceOSes}
                  deviceTypes={deviceTypes}
                  globalCategory={globalCategory}
                />
              );
            },
            fieldSelector: (props) => <FiltersFieldSelector {...props} isDisabled={loading} loading={loading} />,
            operatorSelector: (props) => (
              <IncomingTypeReusible {...props} isDisabled={loading} loading={loading} fields={fields} />
            ),
            combinatorSelector: (props) => <CustomCombinatorSelector {...props} isDisabled={loading} />,
            addRuleAction: (props) => <CustomActionButton {...props} />,
            addGroupAction: (props) =>
              groupButton ? (
                <CustomActionButton
                  {...props}
                  actionType="addGroup"
                  handleOnClick={(e) => {
                    const groupDepth = props.level + 1;
                    if (groupDepth <= 5) {
                      props.handleOnClick(e);
                    } else {
                      setShowAlert(true);
                      setTimeout(() => {
                        setShowAlert(false);
                      }, 2500);
                    }
                  }}
                />
              ) : null,
            removeRuleAction: (props) => <CustomActionButton {...props} actionType="remove" />,
            removeGroupAction: (props) => <CustomActionButton {...props} actionType="remove" />,
          }}
          enableDragAndDrop={!loading}
          autoSelectOperator={false}
          showCombinatorsBetweenRules={false}
        />
      </QueryBuilderDnD>
      {showAlert && (
        <SlideAlert message={t("caymland.query.group.nest.message")} show={showAlert} setShowAlert={setShowAlert} />
      )}
    </>
  );
};

export default QueryFiltersReusible;
